<template>
  <div class="roulette">
    <div class="rouletteMain">
      <div class="rouletteMain_wrap">
        <ul class="rouletteMain_head">
          <li class="rouletteMain_item" style="list-style-type: none;"><a class="active" href="/">Home</a></li>
        </ul>
        <div class="rouletteMain_body">
          <ul class="rouletteMain_awards" style="list-style-type: none; height:1000px">
            <li class="rouletteMain_awards_item" v-for="(n, k) in wheelInfo.event_rewards" v-if="n.name.toLowerCase() != 'miss'">
              <div>
                <div class="rouletteMain_awards_medals"></div>
                <div class="rouletteMain_awards_prize"><img :src="`${n.rewardspot_image_url}`" alt="JAYA9-exchange" /></div>
                <div class="rouletteMain_awards_text">{{ n.name }}</div>
              </div>
            </li>
          </ul>
          <div class="roulette-period">{{ wheelInfo.start_date | shortDate }} - {{ wheelInfo.end_date | shortDate }}</div>
          <div class="rouletteMain_box">
            <div class="roulette_main_numberTimes_left">
              <div>
                <div>
                  <span class="font-weight-bold title">{{ wheelInfo.event_setting.coin_required }}</span>
                  <span>coins</span>
                  <br />
                  /swing
                </div>
              </div>
            </div>
            <div class="rouletteMain_box_wrap rouletteMain_P8_N8">
              <ul class="rouletteMain_box_part rouletteMain_box_part-8">
                <li class="rouletteMain_box_part_list" v-for="(n, k) in wheelInfo.event_rewards">
                  <div class="rouletteMain_box_part_color"></div>
                  <span class="rouletteMain_box_part_award">{{ n.name }}</span>
                </li>
              </ul>
              <div class="rouletteMain_box_coin">
                <span class="rouletteMain_box_coinWrap"><img src="https://jiliwin.9terawolf.com/images/wof/1.png" class="rouletteMain_box_coin_1" /></span>
                <span class="rouletteMain_box_coinWrap"><img src="https://jiliwin.9terawolf.com/images/wof/2.png" class="rouletteMain_box_coin_2" /></span>
                <span class="rouletteMain_box_coinWrap"><img src="https://jiliwin.9terawolf.com/images/wof/3.png" class="rouletteMain_box_coin_3" /></span>
                <span class="rouletteMain_box_coinWrap"><img src="https://jiliwin.9terawolf.com/images/wof/4.png" class="rouletteMain_box_coin_4" /></span>
                <span class="rouletteMain_box_coinWrap"><img src="https://jiliwin.9terawolf.com/images/wof/5.png" class="rouletteMain_box_coin_5" /></span>
                <span class="rouletteMain_box_coinWrap"><img src="https://jiliwin.9terawolf.com/images/wof/6.png" class="rouletteMain_box_coin_6" /></span>
              </div>
              <div class="rouletteMain_box_btn" :style="{ 'background-image': `url('${wheelInfo.event_setting.start_button_image_url}'` }"></div>
              <div class="rouletteMain_box_numberTimes">
                <div>
                  <div>
                    <span>{{ memberInfo.rewardBalance ? memberInfo.rewardBalance : 0 }}</span>
                    <span>coins</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="rouletteMain_winner">
            <div class="rouletteMain_winner_wrap">
              <div class="rouletteMain_winner_head winnerList">
                <div class="rouletteMain_winner_head_item" @click="showWinnerList()">Winner's list</div>
                <div class="rouletteMain_winner_head_item" @click="showRecordList()">Winning record</div>
                <div :class="['rouletteMain_winner_head_cursor', winList ? 'leftMost' : 'fiftyLeft']"></div>
              </div>
              <div class="rouletteMain_winner_slider">
                <ul class="rouletteMain_winner_list pl-0" v-show="winList">
                  <li class="rouletteMain_winner_item" v-for="(n, k) in wheelLogs">
                    <span class="rouletteMain_winner_date">{{ n.draw_date | shortDate }}</span>
                    <span>{{ n.membercode }}</span>
                    <span class="rouletteMain_winner_prize">{{ parseFloat(n.win_amount).toFixed(2) }}</span>
                  </li>
                </ul>
                <ul class="rouletteMain_winner_list pl-0" v-show="recordList">
                  <li class="rouletteMain_winner_item" v-for="(n, k) in wheelMyLogs">
                    <span class="rouletteMain_winner_date">{{ n.draw_date | shortDate }}</span>
                    <span>{{ n.membercode }}</span>
                    <span class="rouletteMain_winner_prize">{{ parseFloat(n.win_amount).toFixed(2) }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="rouletteRule">
      <div class="rouletteRule_wrap">
        <h3 class="rouletteRule_title">{{ $t(`luckyWheel.t&c`) }}</h3>
        <p class="rouletteRule_text"></p>
        <p>
          <strong>
            <span style="font-size:14px">{{ $t(`luckyWheel.title`) }}</span>
          </strong>
        </p>
        <p>
          <strong>
            <span style="font-size:14px">
              {{ $t(`luckyWheel.subtitle`).replace('{coinRequired}', coinRequired) }}
            </span>
          </strong>
        </p>
        <p></p>
        <p>
          <strong>
            <span style="font-size:14px">{{ $t(`luckyWheel.howCollect`) }}</span>
          </strong>
        </p>
        <p>
          {{ $t(`luckyWheel.ptsCollect`) }}
        </p>
        <p>
          <span style="font-size:14px">
            {{ $t(`luckyWheel.requiredCoin`).replace('{coinRequired}', coinRequired) }}
          </span>
        </p>
        <p></p>
        <p>
          <strong>
            <span style="font-size:14px">{{ $t(`luckyWheel.t&c`) }}</span>
          </strong>
        </p>
        <p>
          <span style="font-size:14px">1. {{ $t('luckyWheel.ulOne').replace('{coinRequired}', coinRequired) }}</span>
        </p>
        <p>
          <span style="font-size:14px">2. {{ $t(`luckyWheel.ulTwo`) }}</span>
        </p>
        <p>
          <span style="font-size:14px">3. {{ $t(`luckyWheel.ulThree`) }}</span>
        </p>
        <p>
          <span style="font-size:14px">4. {{ $t(`luckyWheel.ulFour`) }}</span>
        </p>
        <p>
          <span style="font-size:14px">5. {{ $t(`luckyWheel.ulFive`) }}</span>
        </p>
        <p>
          <span style="font-size:14px">6. {{ $t(`luckyWheel.ulSix`) }}</span>
        </p>
        <p>
          <strong>
            <span style="font-size:14px">{{ $t(`luckyWheel.ulSeven`) }}</span>
          </strong>
        </p>
      </div>
    </div>
    <!--    <div class="roulettePopup" v-show="false">-->
    <!--      <div class="roulettePopup_wrap">-->
    <!--        <div class="roulettePopup_box"><p>Congratulations go to</p>-->
    <!--          <h3></h3><img src="https://csi.20icipp.comundefined">-->
    <!--          <div class="roulettePopup_btnWrap">-->
    <!--            <div class="roulettePopup_btn disabled">Roll again</div>-->
    <!--          </div>-->
    <!--          <div class="roulettePopup_btn">OK</div>-->
    <!--          <div class="roulettePopup_close">-->
    <!--            <div></div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--Pop-up box-->
    <!--    <v-dialog class="pop_box" max-width="500px" v-model="isWinPrize" transition="top">-->

    <!--      <v-card color="white" min-height="200" max-width="500px">-->
    <!--        <v-card-title class="primary white&#45;&#45;text">-->
    <!--          V3 Wheel Of Fortune-->
    <!--        </v-card-title>-->
    <!--        <div class="pop_con pa-5">-->
    <!--          <h3 v-if="error != ''">{{ error }}</h3>-->
    <!--          <h3 v-else>Congratulations, {{ win_amount }} has been credited to your-->
    <!--            wallet.</h3>-->
    <!--          <h3 v-else>Ops, sorry please try again next time</h3>-->
    <!--        </div>-->
    <!--        <v-card-actions class="d-flex justify-end">-->
    <!--          <v-btn @click="hidePopup()" ripple outlined color="primary">OK</v-btn>-->
    <!--        </v-card-actions>-->
    <!--      </v-card>-->

    <!--    </v-dialog>-->
  </div>
</template>

<script>
import { RESET_WHEEL_SPIN, WHEEL_CHANCES, WHEEL_GET_MY_LOGS, WHEEL_LOGS, WHEEL_MODULE, WHEEL_SPIN } from '@/store/wheel.module'
import { errorCodeHelper, locale, uiHelper } from '@/util'
import { SHARED } from '@/constants/constants'
import { MEMBER_DETAIL } from '@/store/member.module'

export default {
  name: 'WheelOfFortune',
  data: () => ({
    currentLanguage: uiHelper.getLanguage(),
    coinRequired: '',
    is_spinning: false,
    wheel_id: 0,
    winList: true,
    recordList: false,
    prizeList: [],
    win_amount: 0,
    reward_id: 0,
    degree: 0,
    arrDegree: [0, -45, -90, -135, 180, 135, 90, 45],
    stopDegree: 0,
    isWinPrize: true,
    error: ''
  }),
  created() {
    this.getMemberDetails()
    this.getWheel()
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    },
    wheelInfo() {
      let k = this.$store.state.wheel.wheel
      return k
    },
    memberInfo() {
      return this.$store.state.member.info
    },
    chances() {
      return this.$store.state.wheel.wheelChances.chances
    },
    wheelLogs() {
      return this.$store.state.wheel.wheelLogs
    },
    wheelMyLogs() {
      return this.$store.state.wheel.wheelMyLogs
    },
    spinResult() {
      return this.$store.state.wheel.wheelSpin.complete
    }
  },
  watch: {
    memberInfo() {
      return this.$store.state.member.info
    },
    spinResult() {
      if (this.$store.state.wheel.wheelSpin.complete) {
        if (this.$store.state.wheel.wheelSpin.success) {
          this.win_amount = this.$store.state.wheel.wheelSpin.win_amount
          this.reward_id = this.$store.state.wheel.wheelSpin.reward_id
          let get_index = this.prizeList.indexOf(this.reward_id)
          this.degree = this.arrDegree[get_index]
          this.stopDegree = this.degree + 360 * 2
          this.rotate()
        } else {
          this.error = this.$store.state.wheel.wheelSpin.error
          this.openWinPopup()
        }
      }
    },
    wheelInfo() {
      this.prizeList = []
      let k = this.$store.state.wheel.wheel
      this.wheel_id = k.id
      this.prizeList = k.event_rewards.map(x => x.id)
      if (this.currentLanguage === 'bd') {
        this.coinRequired = this.convertToBengaliNumerals(k.event_setting.coin_required)
      } else {
        this.coinRequired = k.event_setting.coin_required
      }
      this.getWheelLogs(k)
      this.getWheelChances(k)
      this.getWheelMyLogs(k)
    },
    wheelLogs() {
      return this.$store.state.wheel.wheelLogs
    },
    wheelMyLogs() {
      return this.$store.state.wheel.wheelMyLogs
    },
    chances() {
      return this.$store.state.wheel.wheelChances.chances
    }
  },
  methods: {
    convertToBengaliNumerals(number) {
      const bengaliNumerals = ['০', '১', '২', '৩', '৪', '৫', '৬', '৭', '৮', '৯']
      return number
        .toString()
        .split('')
        .map(digit => bengaliNumerals[parseInt(digit)])
        .join('')
    },
    getMemberDetails() {
      this.$store.dispatch(`${MEMBER_DETAIL}`)
    },
    closeWinDialog() {
      this.$parent.closePageDialog()
      this.resetWheel()
      this.is_spinning = false
    },
    openWinPopup() {
      let dialog = this.$parent.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.wofv3`)

      if (this.error != '') {
        dialog.message.push(this.error)

        dialog.button.push({
          title: locale.getMessage(`label.close`),
          action: () => {
            this.closeWinDialog()
          }
        })
      } else if (this.win_amount == 0) {
        dialog.message.push(locale.getMessage(`message.congratsZero`, [this.$options.filters.currency(this.win_amount, 'BDT')]))
        dialog.button.push({
          title: locale.getMessage(`label.ok`),
          action: () => {
            this.closeWinDialog()
          }
        })
      } else {
        dialog.message.push(locale.getMessage(`message.congrats`, [this.$options.filters.currency(this.win_amount, 'BDT')]))
        dialog.button.push({
          title: locale.getMessage(`label.ok`),
          action: () => {
            this.closeWinDialog()
          }
        })
      }
      dialog.dialogXButton = null
      this.$parent.openPageDialog(dialog)
    },
    rotate() {
      $('.rouletteMain_box_part').css('transition', 'all 0.5s ease;')
      $('.rouletteMain_box_part').css('transform', 'rotate(' + this.degree + 'deg)')
      let func = this
      var timer = setTimeout(function() {
        if (func.degree >= func.stopDegree) {
          window.clearTimeout(timer)
          setTimeout(function() {
            func.openWinPopup()
          }, 1000)
        } else {
          func.degree += 5
          window.requestAnimationFrame(func.rotate())
        }
      }, 1)
    },
    showWinnerList() {
      this.winList = true
      this.recordList = false
    },
    showRecordList() {
      this.recordList = true
      this.winList = false
    },
    resetWheel() {
      let filter = {
        id: this.wheel_id
      }
      this.$store.dispatch(`${RESET_WHEEL_SPIN}`, { filter })
      this.$store.dispatch(`${WHEEL_CHANCES}`, { filter })
      this.getMemberDetails()
      let reset = 0
      $('.rouletteMain_box_part').css('-webkit-transform', 'rotate(' + reset + 'deg)')
    },
    getWheelLogs(k) {
      let filter = {
        id: k.id
      }
      this.$store.dispatch(`${WHEEL_LOGS}`, { filter })
    },
    getWheelChances(k) {
      let filter = {
        id: k.id
      }
      this.$store.dispatch(`${WHEEL_CHANCES}`, { filter })
    },
    getWheelMyLogs(k) {
      let filter = {
        id: k.id
      }
      this.$store.dispatch(`${WHEEL_GET_MY_LOGS}`, { filter })
    },
    getWheel() {
      let currency = uiHelper.getCurrency()
      let filter = {
        currency: currency
      }
      this.$store.dispatch(`${WHEEL_MODULE}`, { filter })
    },
    spinWheel() {
      if (!this.is_spinning) {
        this.is_spinning = true
        let filter = {
          id: this.wheel_id
        }
        this.$store.dispatch(`${WHEEL_SPIN}`, { filter })
      }
    }
  },
  async mounted() {
    let func = this
    let degree = 270
    let arrDegree = [0, -45, -90, -135, 180, 135, 90, 45]
    let stopDegree = 270 + 360 * 6

    /**
     * Created by Administrator on 2017/7/5.
     * 红包雨
     */
    function rotate() {
      // $('.rouletteMain_box_part').css('-webkit-transform', 'rotate(' + degree + 'deg)');
      // var timer = setTimeout(function () {
      //   if (degree >= stopDegree) {
      //     window.clearTimeout(timer);
      //   } else {
      //     degree += 5;
      //     rotate();
      //   }
      //
      // }, 1)
    }

    $(document).ready(function() {
      $('.rouletteMain_box_btn').on('click', function() {
        if (!func.isLoggedIn) {
          func.$parent.openLoginDialog()
          return
        }
        func.spinWheel()
      })
    })
  }
}
</script>

<style scoped>
.roulette_main_numberTimes_left {
  height: 0;
  padding-bottom: 17.549296%;
  left: 2%;
  top: 2%;
  text-align: center;
  width: 24.2253521127%;
  position: absolute;
  color: white;
}

.leftMost {
  left: 0% !important;
}

.fiftyLeft {
  left: 50% !important;
}

.rc-trigger-popup {
  left: -9999px;
  position: absolute;
  top: -9999px;
  z-index: 1050;
}

.rc-trigger-popup-hidden {
  display: none;
}

.rc-trigger-popup-zoom-appear,
.rc-trigger-popup-zoom-enter {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-play-state: paused;
  animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
  opacity: 0;
}

.rc-trigger-popup-zoom-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-play-state: paused;
  animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
}

.rc-trigger-popup-zoom-appear.rc-trigger-popup-zoom-appear-active,
.rc-trigger-popup-zoom-enter.rc-trigger-popup-zoom-enter-active {
  animation-name: rcTriggerZoomIn;
  animation-play-state: running;
}

.rc-trigger-popup-zoom-leave.rc-trigger-popup-zoom-leave-active {
  animation-name: rcTriggerZoomOut;
  animation-play-state: running;
}

@keyframes rcTriggerZoomIn {
  0% {
    opacity: 0;
    transform: scale(0);
    transform-origin: 50% 50%;
  }

  to {
    opacity: 1;
    transform: scale(1);
    transform-origin: 50% 50%;
  }
}

@keyframes rcTriggerZoomOut {
  0% {
    opacity: 1;
    transform: scale(1);
    transform-origin: 50% 50%;
  }

  to {
    opacity: 0;
    transform: scale(0);
    transform-origin: 50% 50%;
  }
}

.rc-trigger-popup-mask {
  background-color: #373737;
  background-color: rgba(55, 55, 55, 0.6);
  bottom: 0;
  filter: alpha(opacity=50);
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1050;
}

.rc-trigger-popup-mask-hidden {
  display: none;
}

.rc-trigger-popup-fade-appear,
.rc-trigger-popup-fade-enter {
  opacity: 0;
}

.rc-trigger-popup-fade-appear,
.rc-trigger-popup-fade-enter,
.rc-trigger-popup-fade-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-play-state: paused;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
}

.rc-trigger-popup-fade-appear.rc-trigger-popup-fade-appear-active,
.rc-trigger-popup-fade-enter.rc-trigger-popup-fade-enter-active {
  animation-name: rcTriggerMaskFadeIn;
  animation-play-state: running;
}

.rc-trigger-popup-fade-leave.rc-trigger-popup-fade-leave-active {
  animation-name: rcDialogFadeOut;
  animation-play-state: running;
}

@keyframes rcTriggerMaskFadeIn {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes rcDialogFadeOut {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.section-promo {
  background: #0e0f12 url(https://csi.20icipp.com/img/static/vn/desktop/home-bg.jpg) top/100% auto no-repeat;
  min-height: calc(100vh - 375px);
  padding: 40px 0;
}

.section-promo .banner-promotion {
  display: none;
}

.section-promo .category {
  margin-bottom: 20px;
}

.section-promo .category .category-item {
  padding: 0 15px;
}

.section-promo .hover-container.promo-detail-modal .container .apply-btn {
  display: flex;
  justify-content: center;
}

.section-promo .promo .nrc-accordion .nrc-collapse .apply-btn {
  display: flex;
  justify-content: center;
  padding: 20px 0 40px;
}

.section-promo .promo .nrc-accordion .nrc-collapse .promo-item {
  box-sizing: border-box;
  padding: 30px 80px;
}

.section-promo .promo .nrc-accordion .nrc-collapse .promo-item h1 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  color: #fff;
  font-size: 30px;
  line-height: 1.4;
  padding-bottom: 10px;
}

.section-promo .promo .nrc-accordion .nrc-collapse .promo-item h1 .promo-end-date {
  color: #3e1283;
  font-size: 15px;
  font-weight: 700;
  padding: 10px 0;
}

.section-promo .promo .nrc-accordion .nrc-collapse .promo-item h1 .promo-end-date span.mps-alert {
  font-size: 20px !important;
}

.section-promo .promo .nrc-accordion .nrc-collapse .promo-item .promo-detail {
  color: #fff;
  font-size: 14px;
  line-height: 1.6;
}

.section-promo .promo .nrc-accordion .nrc-collapse .promo-item .promo-detail strong {
  font-weight: 700 !important;
}

.section-promo .hover-container .container .header {
  justify-content: flex-end;
}

.section-promo .nrc-editor-view-mode p:empty {
  padding-bottom: 3px;
}

.RED .getRedBag__box__DLT,
.RED .getRedBag__box__DRT,
.RED .getRedBag__box__ULT,
.RED .getRedBag__box__URT,
.RED .getRedBag__wrap__DLT,
.RED .getRedBag__wrap__DRT,
.RED .getRedBag__wrap__ULT,
.RED .getRedBag__wrap__URT {
  height: 22px;
  position: absolute;
  width: 22px;
}

.BLACK .contentWrap:before,
.BLACK .getRedBag:before,
.BLACK .redBagLottery__content > p:after,
.BLACK .redBagLottery__content > p:before,
.BLACK .redBagLottery__wrap:after,
.BLACK .redBagLottery__wrap:before,
.BLACK .redBanner__fixed:after,
.BLACK .redBanner__fixed:before,
.BLACK .redBanner__fixed__content:after,
.redBagLottery:before,
.redBagLottery__close:after,
.redBagLottery__close:before,
.redBagLottery__content:after,
.redBagLottery__content:before,
.redBagLottery__content > div:after,
.redBanner:after,
.redBanner:before {
  content: '';
  pointer-events: none;
  position: absolute;
}

.BLACK .contentWrap:before,
.BLACK .getRedBag:before,
.BLACK .redBanner__fixed:after,
.BLACK .redBanner__fixed__content:after,
.redBanner:after,
.redBanner:before,
.redBanner__fixed {
  background-size: cover;
  height: 0;
  left: 0;
  width: 100%;
}

.BLACK .redBanner__timeBox,
.BLACK .redBanner__timeUnit,
.BLACK .redBanner__title {
  position: relative;
  z-index: 1;
}

@keyframes banner_fixed-active {
  0% {
    opacity: 0.4;
    transform: translate3d(-50%, 0, 0) scale3d(0.4, 0.4, 1);
  }

  to {
    opacity: 1;
    transform: translate3d(-50%, 0, 0) scaleX(1);
  }
}

@keyframes redBagLottery-active {
  0% {
    opacity: 0.4;
    transform: scale3d(0.4, 0.4, 1);
  }

  to {
    opacity: 1;
    transform: scaleX(1);
  }
}

@keyframes lottery_money-moveRED {
  0%,
  to {
    transform: translate3d(4px, -4px, 0);
  }

  35% {
    transform: translate3d(-8px, -8px, 0);
  }

  65% {
    transform: translate3d(-12px, 12px, 0);
  }
}

@keyframes banner_foreground-move {
  0%,
  to {
    transform: scale(1.18) translate3d(-4px, 4px, 0);
  }

  35% {
    transform: scale(1.18) translate3d(8px, 8px, 0);
  }

  65% {
    transform: scale(1.18) translate3d(12px, -12px, 0);
  }
}

@keyframes lottery_star-flash {
  0% {
    opacity: 0;
    transform: rotate(45deg);
  }

  16.665% {
    opacity: 1;
    transform: rotate(79deg);
  }

  49.995% {
    opacity: 1;
    transform: rotate(146deg);
  }

  66.66%,
  to {
    opacity: 0;
    transform: rotate(180deg);
  }
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  10% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  20% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  30% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  40% {
    transform: scale3d(0.97, 0.97, 0.97);
  }

  50%,
  to {
    opacity: 1;
    transform: scaleX(1);
  }
}

@keyframes wobble {
  0%,
  to {
    transform: translateZ(0);
  }

  7.5% {
    transform: translate3d(-25%, 0, 0) rotate(-5deg);
  }

  15% {
    transform: translate3d(20%, 0, 0) rotate(3deg);
  }

  22.5% {
    transform: translate3d(-15%, 0, 0) rotate(-3deg);
  }

  30% {
    transform: translate3d(10%, 0, 0) rotate(2deg);
  }

  37.5% {
    transform: translate3d(-5%, 0, 0) rotate(-1deg);
  }
}

@keyframes lottery_money-moveBLACK {
  0% {
    transform: translateZ(0);
  }

  35% {
    transform: translate3d(3px, 0, 0);
  }

  65% {
    transform: translate3d(-3px, 0, 0);
  }

  to {
    transform: translate3d(9px, 0, 0);
  }
}

@keyframes redBannerGirlBoy {
  0% {
    transform: translateZ(0);
  }

  20% {
    transform: translate3d(-5%, 0, 0) rotate(-5deg);
  }

  40% {
    transform: translate3d(4%, 0, 0) rotate(3deg);
  }

  60% {
    transform: translate3d(-3%, 0, 0) rotate(-3deg);
  }

  80% {
    transform: translate3d(2%, 0, 0) rotate(2deg);
  }

  to {
    transform: translate3d(-1%, 0, 0) rotate(-1deg);
  }
}

@keyframes secondHandRotate {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(1turn);
  }
}

@keyframes jackInTheBox {
  0% {
    opacity: 0.75;
    transform: scale(0.8) rotate(14deg);
  }

  50% {
    transform: scale(0.9) rotate(-10deg);
  }

  75% {
    transform: scale(0.95) rotate(2deg);
  }

  to {
    opacity: 1;
    transform: scale(1) rotate(-1deg);
  }
}

@keyframes swing {
  0% {
    transform: scale(0.85) rotate(1deg);
  }

  25% {
    transform: scale(0.85) rotate(-4deg);
  }

  50% {
    transform: scale(0.85) rotate(-1deg);
  }

  75% {
    transform: scale(0.85) rotate(-3deg);
  }

  to {
    transform: scale(0.85) rotate(-2deg);
  }
}

.red-envelope {
  background-color: #750f0f;
  font-size: 16px;
  line-height: 1.8;
  position: relative;
}

.contentWrap,
.redBagLottery,
.redBanner,
.redBanner__fixed,
.redFoot,
.redHead {
  min-width: 1000px;
}

.zh-CN.RED .redBanner__fixed__content--expired > .redBanner__title,
.zh-CN .redBanner__fixed__content--none > .redBanner__title {
  font-size: 3.42vw;
  padding-top: 6.2%;
}

.zh-CN.BLACK .redBanner__fixed__content > .redBanner__title {
  letter-spacing: 2px;
}

.zh-CN.BLACK .redBanner__fixed__content--expired > .redBanner__title {
  line-height: 1.65;
}

.zh-CN.BLACK .redBanner__fixed__content--in_progress > .redBanner__title {
  padding: 0 15%;
}

.redHead {
  background-color: #fff;
  margin: 0 auto -60px;
  max-width: 1440px;
  padding: 10px 0;
  position: relative;
  top: 0;
  z-index: 1;
}

.redHead__wrap {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1000px;
}

.redHead__logo {
  height: 40px;
}

.redHead__logo__img {
  height: 100%;
}

.redHead__menu {
  display: flex;
}

.redHead__menu__item {
  font-size: 16px;
  line-height: 40px;
  position: relative;
  text-align: center;
}

.redHead__menu__item + .redHead__menu__item:after {
  background-color: #232323;
  content: '';
  height: 16px;
  left: 0;
  position: absolute;
  top: 12px;
  width: 1px;
}

.redHead__menu__item > a {
  color: #232323;
  cursor: pointer;
  display: block;
  padding: 0 20px;
  text-decoration: none;
  transition: color 0.4s;
}

.redHead__menu__item > a:hover {
  color: rgba(0, 0, 0, 0.5);
}

.redHead__menu__item--winQuery:after {
  display: none;
}

.redHead__menu__item--winQuery > a {
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  font-weight: 700;
  height: 28px;
  line-height: 26px;
  margin: 5px 0 5px 20px;
  padding: 0 24px;
  transition: color 0.4s, background-color 0.4s;
}

.redHead__menu__item--winQuery > a:hover {
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
}

.redBanner {
  height: 0;
  margin: 0 auto;
  max-width: 1440px;
  overflow: hidden;
  padding-bottom: calc(41.38889% + 60px);
  position: relative;
}

.redBanner:after,
.redBanner:before {
  z-index: 1;
}

.redBanner:before {
  background-image: url(https://csi.20icipp.com/img/static/red-envelope/banner__bottom.png);
  bottom: 0;
  padding-bottom: 6.5277777778%;
}

.redBanner__fixed {
  background-image: url(https://csi.20icipp.com/img/static/red-envelope/bannerbg.jpg);
  background-position: top;
  padding-bottom: calc(41.38889% + 60px);
  position: fixed;
  top: 0;
}

.redBanner__fixed__content {
  bottom: 2.3%;
  height: 174px;
  left: 50%;
  position: absolute;
  transform: translate(-50%);
  width: 350px;
}

.redBanner__fixed__content--expired,
.redBanner__fixed__content--in_progress,
.redBanner__fixed__content--none,
.redBanner__fixed__content--upcoming {
  animation: banner_fixed-active 0.4s;
}

.redBanner__fixed__content--expired,
.redBanner__fixed__content--none {
  height: 26.7692307692%;
  width: 24.3055555556%;
}

.redBanner__fixed__content--expired > .redBanner__title,
.redBanner__fixed__content--none > .redBanner__title {
  font-size: 2.9vw;
  line-height: 1.3;
}

.redBanner__fixed__content--none > .redBanner__title {
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-image: linear-gradient(119deg, #831f20, #c8333a 50%, #781c1c);
}

.redBanner__title {
  font-weight: 700;
  text-align: center;
  transform: skew(-3deg);
}

.redBanner__timeBox {
  display: flex;
  justify-content: center;
}

.redBanner__timeBox__timeWord + .redBanner__timeBox__timeWord:before {
  content: ':';
}

.redBanner__timeUnit {
  display: flex;
  justify-content: space-between;
}

.contentWrap {
  background-image: linear-gradient(180deg, #750f0f, #b02e31);
  margin: 0 auto;
  max-width: 1440px;
  padding-bottom: 150px;
  position: relative;
}

.sectionWrap {
  margin: 0 auto;
  max-width: 910px;
}

.sectionWrap__title {
  color: #f9dfc7;
  font-size: 24px;
  line-height: 34px;
  padding: 34px 0;
  text-align: center;
}

.getRedBag__wrap {
  background-color: #c8333a;
  border-radius: 15px;
  position: relative;
}

.getRedBag__box {
  padding: 11px;
}

.getRedBag__box__list__box {
  overflow: hidden;
}

.getRedBag__box__list__scroll {
  align-content: flex-start;
  display: flex;
  flex-wrap: wrap;
  height: 104px;
  justify-content: space-between;
  position: relative;
  top: 0;
  transition: top 0.5s;
}

.getRedBag__box__list__scroll__item {
  box-sizing: border-box;
  flex: 0 0 50%;
  font-size: 14px;
  font-weight: 700;
  line-height: 26px;
  padding-left: 26px;
}

.getRedBag__box__list__scroll__item > b {
  border-radius: 20px;
  display: inline-block;
  font-size: 15px;
  height: 20px;
  line-height: 20px;
  margin-right: 16px;
  text-align: center;
  width: 26px;
}

.getRedBag__box__list__scroll__item > span {
  margin: 0 10px;
}

.ruleActivity .nrc-editor-view-mode {
  counter-reset: p;
}

.ruleActivity .nrc-editor-view-mode > p {
  margin-bottom: 6px;
  padding-left: 32px;
  position: relative;
}

.ruleActivity .nrc-editor-view-mode > p:before {
  border-radius: 50%;
  content: counter(p);
  counter-increment: p;
  font-weight: 700;
  height: 20px;
  left: 0;
  line-height: 20px;
  position: absolute;
  text-align: center;
  top: 4px;
  width: 20px;
}

.ruleActivity .nrc-editor-view-mode img {
  margin: 12px 0;
  width: 80%;
}

.redFoot {
  background-color: #781c1c;
  color: #c8333a;
  font-size: 14px;
  height: 50px;
  line-height: 50px;
  max-width: 1440px;
  text-align: center;
}

.redFoot,
.redFoot__wrap {
  margin: 0 auto;
  position: relative;
}

.redFoot__wrap {
  max-width: 1100px;
}

.redFoot__redBag {
  align-items: center;
  display: flex;
  height: 166px;
  justify-content: center;
  margin-top: -120px;
  position: absolute;
  right: 0;
  top: 0;
  width: 171px;
}

.redFoot__redBag img {
  display: block;
  filter: drop-shadow(0 2px 7px rgba(0, 0, 0, 0.2));
  max-height: 95%;
  max-width: 95%;
}

.redBagLottery {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1001;
}

.redBagLottery:before {
  left: 0;
  top: 0;
}

.redBagLottery__wrap {
  animation: redBagLottery-active 0.4s;
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
}

.redBagLottery__content {
  margin-bottom: 30px;
  position: relative;
  text-align: center;
  width: 100%;
}

.redBagLottery__content > div:after {
  animation: lottery_star-flash 3s linear infinite;
  background-image: url(https://csi.20icipp.com/img/static/red-envelope/lottery__star.png);
  height: 118px;
  transform: rotate(-15deg);
  width: 118px;
  z-index: 1;
}

.redBagLottery__content__btn {
  border-radius: 50px;
  color: #fff;
  margin: 0 auto;
}

.redBagLottery__close {
  border: 1px solid #fff;
  border-radius: 50%;
  bottom: 30px;
  cursor: pointer;
  height: 48px;
  left: 50%;
  margin-left: -24px;
  position: relative;
  position: absolute;
  transition: transform 0.3s;
  width: 48px;
}

.redBagLottery__close:after,
.redBagLottery__close:before {
  background-color: #fff;
  bottom: 0;
  box-shadow: 0 0 1px #fff;
  height: 1px;
  left: 0;
  margin: auto;
  right: 0;
  top: 0;
  width: 34px;
}

.redBagLottery__close:before {
  transform: rotate(45deg);
}

.redBagLottery__close:after {
  transform: rotate(135deg);
}

.redBagLottery__close:hover {
  box-shadow: 0 -1px 2px #000;
  transform: rotate(180deg);
}

.RED .redHead__menu__item > a:hover {
  color: #c8333a;
}

.RED .redHead__menu__item--winQuery > a {
  border: 1px solid #c8333a;
  color: #c8333a;
}

.RED .redHead__menu__item--winQuery > a:hover {
  background-color: #c8333a;
  color: #fff;
}

.RED .redBanner {
  padding-bottom: 618px;
}

.RED .redBanner:before {
  bottom: 32px;
  box-shadow: 0 32px 0 #750f0f;
}

.RED .redBanner:after {
  animation: banner_foreground-move 7s linear infinite;
  background-image: url(https://csi.20icipp.com/img/static/red-envelope/banner__foreground.png);
  left: 50%;
  margin-left: -543px;
  padding-bottom: 450px;
  top: 13.8%;
  transform: scale(1.15);
  width: 1085px;
}

.RED .redBanner__fixed {
  padding-bottom: 584px;
}

.RED .redBanner__fixed__img {
  left: 50%;
  position: absolute;
  top: 20%;
  transform: translate(-50%);
  width: 517px;
}

.RED .redBanner__fixed__content {
  color: #781c1c;
}

.RED .redBanner__fixed__content--expired > .redBanner__title {
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-image: linear-gradient(119deg, #831f20, #c8333a 50%, #781c1c);
}

.RED .redBanner__fixed__content--in_progress > .redBanner__title,
.RED .redBanner__fixed__content--upcoming > .redBanner__title {
  font-size: 24px;
  line-height: 40px;
  margin-bottom: 15px;
  white-space: nowrap;
}

.RED .redBanner__fixed__content--upcoming > .redBanner__title:after,
.RED .redBanner__fixed__content--upcoming > .redBanner__title:before {
  content: '';
  height: 1px;
  margin-top: -1px;
  position: absolute;
  top: 50%;
  width: 80px;
}

.RED .redBanner__fixed__content--upcoming > .redBanner__title:before {
  background-image: linear-gradient(270deg, #781c1c, rgba(120, 28, 28, 0));
  left: 0;
}

.RED .redBanner__fixed__content--upcoming > .redBanner__title:after {
  background-image: linear-gradient(90deg, #781c1c, rgba(120, 28, 28, 0));
  right: 0;
}

.RED .redBanner__timeBox {
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid #e5bb93;
  border-radius: 8px;
  box-shadow: inset 0 -1px 1px 0 rgba(255, 255, 255, 0.3), inset 0 1px 2px 0 rgba(198, 128, 85, 0.3);
  color: #c8333a;
  font-family: Times;
  font-size: 65px;
  font-weight: 700;
  height: 80px;
  line-height: 80px;
}

.RED .redBanner__timeUnit {
  color: #781c1c;
  font-size: 20px;
  padding: 3px 45px;
}

.RED .contentWrap {
  background-image: url(https://csi.20icipp.com/img/static/red-envelope/contentWrapLeftBg.png), url(https://csi.20icipp.com/img/static/red-envelope/contentWrapRightBg.png),
    linear-gradient(180deg, #750f0f, #b02e31);
  background-position: 0, 100%, 0 0;
  background-repeat: no-repeat;
}

.RED .sectionWrap__title {
  margin: 0 66px;
  position: relative;
}

.RED .sectionWrap__title:after,
.RED .sectionWrap__title:before {
  content: '';
  height: 1px;
  margin-top: -1.5px;
  position: absolute;
  top: 50%;
  width: 280px;
}

.RED .sectionWrap__title:before {
  background-image: linear-gradient(270deg, #f9dfc7, hsla(29, 81%, 88%, 0));
  left: 0;
}

.RED .sectionWrap__title:after {
  background-image: linear-gradient(90deg, #f9dfc7, hsla(29, 81%, 88%, 0));
  right: 0;
}

.RED .sectionWrap__title__deco {
  display: block;
  margin: 0 auto;
  position: relative;
  width: 204px;
}

.RED .sectionWrap__title__deco:after,
.RED .sectionWrap__title__deco:before {
  background-color: #f9dfc7;
  content: '';
  height: 10px;
  margin-top: -6px;
  position: absolute;
  top: 50%;
  transform: rotate(45deg);
  width: 10px;
}

.RED .sectionWrap__title__deco:before {
  left: -10px;
}

.RED .sectionWrap__title__deco:after {
  right: -10px;
}

.RED .getRedBag__wrap {
  background-image: linear-gradient(180deg, #fd6161, #8f1919);
  box-shadow: 0 4px 17px 12px rgba(53, 7, 7, 0.22);
  padding: 13px;
}

.RED .getRedBag__wrap:before {
  background-image: linear-gradient(180deg, #a92c2d, #a02a2c);
  border-radius: 14px;
  content: '';
  height: calc(100% - 2px);
  left: 1px;
  position: absolute;
  top: 1px;
  width: calc(100% - 2px);
}

.RED .getRedBag__wrap__ULT,
.RED .getRedBag__wrap__URT {
  background-color: #a92c2d;
  top: 13px;
}

.RED .getRedBag__wrap__DLT,
.RED .getRedBag__wrap__DRT {
  background-color: #a02a2c;
  bottom: 13px;
}

.RED .getRedBag__wrap__DLT,
.RED .getRedBag__wrap__ULT {
  left: 13px;
}

.RED .getRedBag__wrap__DRT,
.RED .getRedBag__wrap__URT {
  right: 13px;
}

.RED .getRedBag__wrap__ULT {
  border-bottom-right-radius: 20px;
  box-shadow: 1px 2px 1px rgba(255, 255, 255, 0.5);
}

.RED .getRedBag__wrap__URT {
  border-bottom-left-radius: 20px;
  box-shadow: -1px 2px 1px rgba(255, 255, 255, 0.5);
}

.RED .getRedBag__wrap__DLT {
  border-top-right-radius: 20px;
}

.RED .getRedBag__wrap__DRT {
  border-top-left-radius: 20px;
}

.RED .getRedBag__box {
  background-image: linear-gradient(270deg, #e5bb92, #fbe3cc 51%, #e5bb92);
  box-shadow: inset 0 2px 1px rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  position: relative;
}

.RED .getRedBag__box__list {
  background-image: linear-gradient(180deg, #781c1c, #320d0d);
  padding: 25px 1px;
  position: relative;
}

.RED .getRedBag__box__list:before {
  background-image: linear-gradient(270deg, #e5bb92, #efcdac 8%, #fbe3cc 51%, #edc9a7 91%, #e5bb92);
  content: '';
  height: calc(100% - 2px);
  left: 1px;
  position: absolute;
  top: 1px;
  width: calc(100% - 2px);
}

.RED .getRedBag__box__list__scroll__item {
  color: #781c1c;
  position: relative;
}

.RED .getRedBag__box__list__scroll__item > b {
  background-color: #781c1c;
  color: #f9dfc7;
  font-weight: 400;
}

.RED .getRedBag__box__DLT,
.RED .getRedBag__box__DRT,
.RED .getRedBag__box__ULT,
.RED .getRedBag__box__URT {
  background-color: #e5bb92;
  border-style: solid;
  box-sizing: border-box;
}

.RED .getRedBag__box__ULT,
.RED .getRedBag__box__URT {
  top: 11px;
}

.RED .getRedBag__box__DLT,
.RED .getRedBag__box__DRT {
  bottom: 11px;
}

.RED .getRedBag__box__DLT,
.RED .getRedBag__box__ULT {
  left: 11px;
}

.RED .getRedBag__box__DRT,
.RED .getRedBag__box__URT {
  right: 11px;
}

.RED .getRedBag__box__ULT {
  border-bottom-right-radius: 20px;
  border-color: transparent #781c1c #781c1c transparent;
  border-width: 0 1px 1px 0;
}

.RED .getRedBag__box__URT {
  border-bottom-left-radius: 20px;
  border-color: transparent transparent #781c1c #781c1c;
  border-width: 0 0 1px 1px;
}

.RED .getRedBag__box__DLT {
  border-color: #320d0d #320d0d transparent transparent;
  border-top-right-radius: 20px;
  border-width: 1px 1px 0 0;
}

.RED .getRedBag__box__DRT {
  border-color: #320d0d transparent transparent #320d0d;
  border-top-left-radius: 20px;
  border-width: 1px 0 0 1px;
}

.RED .ruleActivity .nrc-editor-view-mode {
  color: #f9dfc7;
  padding: 6px 30px 0;
}

.RED .ruleActivity .nrc-editor-view-mode > p:before {
  background-color: #f9dfc7;
  color: #781c1c;
}

.RED .redFoot__redBag {
  animation: wobble 2s infinite;
}

.RED .redBagLottery {
  background-color: rgba(69, 16, 16, 0.8);
}

.RED .redBagLottery:before {
  background-color: rgba(0, 0, 0, 0.25);
  height: 100%;
  width: 100%;
}

.RED .redBagLottery__wrap {
  min-height: 500px;
  width: 400px;
}

.RED .redBagLottery__content {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 4px 17px 12px rgba(53, 7, 7, 0.35);
  box-sizing: border-box;
  color: #781c1c;
  font-size: 30px;
  font-weight: 700;
  left: 50%;
  margin: 0;
  min-height: 280px;
  padding: 140px 10px 20px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.RED .redBagLottery__content:after,
.RED .redBagLottery__content:before {
  background-size: cover;
}

.RED .redBagLottery__content:before {
  background-image: url(https://csi.20icipp.com/img/static/red-envelope/lottery__head.png);
  height: 245px;
  left: 0;
  top: -105px;
  width: 100%;
}

.RED .redBagLottery__content:after {
  animation: lottery_money-moveRED 7s linear infinite;
  background-image: url(https://csi.20icipp.com/img/static/red-envelope/lottery__money--m.png);
  height: 280px;
  left: -64px;
  top: -115px;
  width: 556px;
}

.RED .redBagLottery__content > p {
  line-height: 42px;
  margin-top: 40px;
}

.RED .redBagLottery__content > div {
  font-size: 50px;
  line-height: 70px;
  margin-bottom: 36px;
}

.RED .redBagLottery__content > div:after {
  left: 85px;
  top: -50px;
}

.RED .redBagLottery__content__btn {
  background-color: #e6454b;
  display: block;
  font-weight: 700;
  height: 60px;
  line-height: 60px;
  position: relative;
  text-decoration: none;
  width: 290px;
  z-index: 1;
}

.RED .redBagLottery__content__btn:before {
  background-image: linear-gradient(180deg, #e6454b, #c9353a);
  border-radius: 30px;
  box-shadow: inset 0 -1px 2px rgba(0, 0, 0, 0.25);
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition: opacity 0.4s;
  width: 100%;
  z-index: -1;
}

.RED .redBagLottery__content__btn:hover:before {
  opacity: 0;
}

.BLACK {
  color: #fff;
}

.BLACK .redHead__menu__item > a:hover {
  color: #f30000;
}

.BLACK .redHead__menu__item--winQuery > a {
  border-color: #502daa;
  border-radius: 20px;
  color: #502daa;
}

.BLACK .redHead__menu__item--winQuery > a:hover {
  background-color: #502daa;
  color: #fff;
}

.BLACK .redBanner {
  padding-bottom: 50%;
}

.BLACK .redBanner:after,
.BLACK .redBanner:before {
  animation: redBannerGirlBoy 18s linear infinite alternate;
  padding-bottom: 50%;
  top: 0;
}

.BLACK .redBanner:before {
  background-image: url(https://csi.20icipp.com/img/static/red-envelope/redBanner__boy.png);
}

.BLACK .redBanner:after {
  animation-direction: alternate-reverse;
  background-image: url(https://csi.20icipp.com/img/static/red-envelope/redBanner__girl.png);
}

.BLACK .redBanner__fixed {
  background-image: url(https://csi.20icipp.com/img/static/red-envelope/bannerFixedBg.png), linear-gradient(180deg, #502daa, #682691 50%, #722386);
  background-repeat: no-repeat;
  background-size: contain;
  padding-bottom: 50%;
  position: fixed;
  top: 0;
}

.BLACK .redBanner__fixed:after,
.BLACK .redBanner__fixed:before {
  bottom: -1px;
}

.BLACK .redBanner__fixed:before {
  background-image: url(https://csi.20icipp.com/img/static/red-envelope/bannerFixed__bottom.png);
  background-size: cover;
  height: 0;
  left: 50%;
  padding-bottom: 12.5694444444%;
  transform: translate(-50%);
  width: 38.1944444444%;
}

.BLACK .redBanner__fixed:after {
  animation: jackInTheBox 24s infinite alternate;
  background-image: url(https://csi.20icipp.com/img/static/red-envelope/bannerFixed__rain.png);
  padding-bottom: 50%;
  transform-origin: center bottom;
}

.BLACK .redBanner__fixed__img {
  left: 50%;
  position: absolute;
  top: 18%;
  transform: translate(-50%);
  width: 22.3611111111%;
}

.BLACK .redBanner__fixed__content {
  background-image: linear-gradient(150deg, #b49fe5, #9171d4 25%, #754ec4 50%, #6336b3 75%, #5b2ba2);
  border: 2px solid #fff;
  border-radius: 50%;
  bottom: 20px;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.35), inset 4px 4px 8px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  height: 48.0555555556%;
  justify-content: center;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  width: 24.0277777778%;
}

.BLACK .redBanner__fixed__content:after {
  animation: secondHandRotate 60s linear infinite reverse;
  background-image: url(https://csi.20icipp.com/img/static/red-envelope/bannerFixedContent__coin.png);
  padding-bottom: 100%;
  top: 0;
}

.BLACK .redBanner__fixed__content--expired > .redBanner__secondHand {
  animation-name: none;
}

.BLACK .redBanner__fixed__content--expired > .redBanner__title {
  font-size: 2.36vw;
  line-height: 1.4;
  padding: 0 10%;
}

.BLACK .redBanner__fixed__content--expired > .redBanner__secondHand {
  border-radius: 50%;
  border-width: 10px;
  box-shadow: 0 0 5px 2px rgba(153, 153, 204, 0.5), inset 0 0 5px 2px rgba(153, 153, 204, 0.5);
  height: 97.6878612717%;
  width: 97.6878612717%;
}

.BLACK .redBanner__fixed__content--expired > .redBanner__secondHand:after,
.BLACK .redBanner__fixed__content--expired > .redBanner__secondHand:before {
  display: none;
}

.BLACK .redBanner__fixed__content--in_progress > .redBanner__title {
  line-height: 1.4;
}

.BLACK .redBanner__title {
  font-size: 21px;
  font-weight: 700;
  line-height: 1;
  padding: 0 8%;
  text-align: center;
}

.BLACK .redBanner__timeBox {
  font-family: Helvetica;
  font-size: 40px;
  height: 72px;
  letter-spacing: 2px;
  line-height: 1.8;
  transform: scaleY(1.15);
}

.BLACK .redBanner__timeUnit {
  font-size: 13px;
  line-height: 1;
  padding: 0 52px;
}

.BLACK .redBanner__secondHand {
  animation: secondHandRotate 60s linear infinite;
  border-color: #fcebff;
  border-radius: 250px 0 0 0;
  border-style: solid;
  border-width: 10px 0 0 10px;
  box-shadow: -2px -2px 2px rgba(153, 153, 204, 0.5), inset 2px 2px 2px rgba(153, 153, 204, 0.5);
  height: 48.8439306358%;
  left: -6px;
  position: absolute;
  top: -6px;
  transform-origin: 100% 100%;
  transition: width 0.4s, height 0.4s, box-shadow 0.4s;
  width: 48.8439306358%;
}

.BLACK .redBanner__secondHand:after,
.BLACK .redBanner__secondHand:before {
  background-color: #fcebff;
  border-radius: 50%;
  content: '';
  height: 10px;
  position: absolute;
  width: 10px;
}

.BLACK .redBanner__secondHand:before {
  box-shadow: -4px 0 0 #fcebff, 0 0 2px 3px rgba(153, 153, 204, 0.5);
  right: -5px;
  top: -10px;
}

.BLACK .redBanner__secondHand:after {
  bottom: -5px;
  box-shadow: 0 -4px 0 #fcebff, 0 0 2px 3px rgba(153, 153, 204, 0.5);
  left: -10px;
}

.BLACK .contentWrap {
  background-image: linear-gradient(196deg, #d00a24, #722387 70%);
  padding-bottom: 0;
}

.BLACK .contentWrap:before {
  background-image: url(https://csi.20icipp.com/img/static/red-envelope/contentWrap__top.png);
  padding-bottom: 15.9722222222%;
  top: 0;
  transform: translateY(-99.4%);
}

.BLACK .sectionWrap__title {
  color: #fff;
  font-size: 28px;
  margin: 0 0 38px;
  padding: 0;
}

.BLACK .getRedBag {
  overflow: hidden;
  padding: 120px 0 190px;
  position: relative;
}

.BLACK .getRedBag:before {
  animation: swing 8s linear infinite alternate;
  background-image: url(https://csi.20icipp.com/img/static/red-envelope/getRedBag__bg.png);
  padding-bottom: 42.0833333333%;
  top: 0;
  transform-origin: right top;
}

.BLACK .getRedBag__wrap {
  background-image: linear-gradient(90deg, #502daa, #7541d1 50%, #522da8);
  border-radius: 26px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5), inset 0 0 20px rgba(255, 255, 255, 0.2);
}

.BLACK .getRedBag__box {
  box-sizing: border-box;
  padding: 54px 34px;
}

.BLACK .getRedBag__box__list__scroll__item > b {
  background-color: #fff;
  color: #502daa;
}

.BLACK .ruleActivity {
  margin-top: -100px;
  padding-bottom: 75px;
}

.BLACK .ruleActivity .nrc-editor-view-mode {
  padding: 6px 0 0;
}

.BLACK .ruleActivity .nrc-editor-view-mode > p {
  line-height: 25px;
  margin-bottom: 4px;
}

.BLACK .ruleActivity .nrc-editor-view-mode > p:before {
  background-color: #fff;
  color: #861e72;
}

.BLACK .redFoot {
  background-color: #502daa;
  color: #fff;
  font-size: 16px;
}

.BLACK .redFoot__redBag {
  display: none;
}

.BLACK .redBagLottery {
  background-image: radial-gradient(rgba(36, 26, 65, 0.85) 52%, rgba(0, 0, 0, 0.85) 100%);
}

.BLACK .redBagLottery:before {
  animation: redBannerGirlBoy 6s linear infinite alternate;
  background-image: url(https://csi.20icipp.com/img/static/red-envelope/lottery__coin.png);
  background-repeat: no-repeat;
  bottom: 0;
  height: 540px;
  margin: auto;
  padding: 0 65px 205px 0;
  right: 0;
  transform-origin: top center;
  width: 495px;
}

.BLACK .redBagLottery__wrap {
  height: 400px;
  padding-top: 150px;
  width: 310px;
}

.BLACK .redBagLottery__wrap:after,
.BLACK .redBagLottery__wrap:before {
  animation: lottery_money-moveBLACK 4s linear infinite alternate;
}

.BLACK .redBagLottery__wrap:before {
  background-image: url(https://csi.20icipp.com/img/static/red-envelope/lotteryWrap__coin.png);
  height: 166px;
  left: 35px;
  top: 14px;
  width: 200px;
  z-index: 1;
}

.BLACK .redBagLottery__wrap:after {
  animation-direction: alternate-reverse;
  background-image: url(https://csi.20icipp.com/img/static/red-envelope/lotteryWrap__boy.png);
  bottom: 30px;
  height: 120px;
  left: -118px;
  width: 210px;
}

.BLACK .redBagLottery__content {
  background-image: linear-gradient(90deg, #bc0f39, #d00924 50%, #b9103d);
  border-radius: 0 0 12px 12px;
  box-shadow: 0 3px 5px 1px rgba(0, 0, 0, 0.35);
  font-size: 24px;
  height: 230px;
  padding-top: 90px;
}

.BLACK .redBagLottery__content:after,
.BLACK .redBagLottery__content:before {
  left: 0;
  width: 100%;
}

.BLACK .redBagLottery__content:before {
  background-image: linear-gradient(180deg, #a31140, #9f103b 25%, #9b0f37 50%, #8d0d2e 75%, #750a26);
  height: 30px;
  top: 0;
}

.BLACK .redBagLottery__content:after {
  background-image: linear-gradient(180deg, #a9154d 10%, #950924 45%);
  border-radius: 150px 150px 0 0;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.35);
  height: 150px;
  top: -135px;
  transform: scaleY(0.8);
  z-index: -1;
}

.BLACK .redBagLottery__content > p {
  line-height: 1;
}

.BLACK .redBagLottery__content > p:after,
.BLACK .redBagLottery__content > p:before {
  border-style: solid;
  height: 0;
  top: 0;
  width: 0;
}

.BLACK .redBagLottery__content > p:after {
  border-color: transparent transparent #bc0f39 #bc0f39;
  border-width: 15px 12px;
  left: 0;
}

.BLACK .redBagLottery__content > p:before {
  border-color: transparent #b9103d #b9103d transparent;
  border-width: 15px 12px;
  right: 0;
}

.BLACK .redBagLottery__content > div {
  font-size: 44px;
  line-height: 76px;
}

.BLACK .redBagLottery__content > div:after {
  left: 20px;
  top: -160px;
}

.BLACK .redBagLottery__content__btn {
  background-image: linear-gradient(90deg, #b43269 10%, #a22846 45%);
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.35), inset -1px -1px 15px 1px rgba(0, 0, 0, 0.3);
  display: block;
  height: 65px;
  line-height: 65px;
  text-decoration: none;
  transition: box-shadow 0.4s;
  width: 200px;
}

.BLACK .redBagLottery__content__btn:hover {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.35), inset -1px -1px 12px 1px transparent;
}

@media screen and (max-width: 1020px) {
  .redBanner__fixed {
    padding-bottom: 476px;
  }

  .RED .redBanner__fixed__content--expired > .redBanner__title,
  .redBanner__fixed__content--none > .redBanner__title {
    font-size: 30px;
  }

  .BLACK .redBanner__fixed {
    padding-bottom: 502px;
  }

  .BLACK .redBanner__fixed__content--expired > .redBanner__title {
    font-size: 24px;
  }

  .zh-CN.RED .redBanner__fixed__content--expired > .redBanner__title,
  .zh-CN .redBanner__fixed__content--none > .redBanner__title {
    font-size: 34px;
  }

  .zh-CN.BLACK .redBanner__fixed__content--expired > .redBanner__title {
    font-size: 24px;
  }
}

@media screen and (max-width: 1050px) {
  .redHead__wrap {
    max-width: 900px;
  }

  .BLACK .redBanner__title {
    font-size: 18px;
  }

  .BLACK .redBanner__timeBox {
    font-size: 35px;
    height: 63px;
  }

  .BLACK .redBanner__timeUnit {
    font-size: 12px;
  }

  .BLACK .contentWrap {
    background-image: linear-gradient(200deg, #d00a24, #722387 70%);
  }
}

@media screen and (min-width: 1280px) {
  .RED .redHead__logo {
    margin-left: 100px;
  }

  .RED .redBanner {
    padding-bottom: calc(41.38889% + 94px);
  }

  .RED .redBanner:after {
    left: 0;
    margin-left: 0;
    padding-bottom: 41.4772727273%;
    top: 45px;
    width: 100%;
  }

  .RED .redBanner__fixed {
    padding-bottom: calc(41.38889% + 60px);
  }

  .RED .redBanner__fixed__img {
    width: 40.8333333333%;
  }

  .RED .contentWrap {
    background-image: url(https://csi.20icipp.com/img/static/red-envelope/contentWrapGiftBg.png), url(https://csi.20icipp.com/img/static/red-envelope/contentWrapLeftBg.png),
      url(https://csi.20icipp.com/img/static/red-envelope/contentWrapRightBg.png), linear-gradient(180deg, #750f0f, #b02e31);
    background-position: 0, 0, 100%, 0 0;
  }

  .BLACK .redBanner__title {
    font-size: 23px;
  }

  .BLACK .redBanner__timeBox {
    font-size: 45px;
    height: 81px;
  }

  .BLACK .redBanner__timeUnit {
    font-size: 14px;
  }
}

@media screen and (min-width: 1441px) {
  .red-envelope {
    overflow: hidden;
  }

  .red-envelope.BLACK {
    background-image: linear-gradient(180deg, #f30000, #682691 68%, #502daa);
  }

  .red-envelope:before {
    box-shadow: 0 0 18px 12px rgba(0, 0, 0, 0.25);
    content: '';
    height: 100%;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translate(-50%);
    width: 1440px;
  }

  .red-envelope .redBanner {
    padding-bottom: 656px;
  }

  .red-envelope .redBanner__fixed {
    left: 50%;
    margin-left: -720px;
    padding-bottom: 656px;
    width: 1440px;
  }

  .redBanner__fixed__content--none {
    height: 174px;
    width: 350px;
  }

  .redBanner__fixed__content--none > .redBanner__title {
    font-size: 42px;
  }

  .RED .redBanner {
    overflow: initial;
  }

  .RED .redBanner:before {
    bottom: 0;
    box-shadow: none;
  }

  .RED .redBanner__fixed__content--expired > .redBanner__title {
    font-size: 42px;
  }

  .BLACK .redBanner,
  .BLACK .redBanner__fixed {
    padding-bottom: 720px;
  }

  .BLACK .redBanner__fixed:after,
  .BLACK .redBanner__fixed:before {
    bottom: 0;
  }

  .BLACK .redBanner__fixed__content--expired > .redBanner__title {
    font-size: 34px;
  }

  .BLACK .redBanner__title {
    font-size: 26px;
  }

  .BLACK .redBanner__timeBox {
    font-size: 50px;
    height: 90px;
  }

  .BLACK .redBanner__timeUnit {
    font-size: 16px;
    padding: 0 16%;
  }

  .BLACK .contentWrap {
    background-image: linear-gradient(194deg, #d00a24, #722387 70%);
  }

  .BLACK .contentWrap:before {
    transform: translateY(-100%);
  }

  .zh-CN.RED .redBanner__fixed__content--expired > .redBanner__title,
  .zh-CN .redBanner__fixed__content--none > .redBanner__title {
    font-size: 50px;
  }

  .zh-CN.BLACK .redBanner__fixed__content--expired > .redBanner__title {
    font-size: 34px;
  }
}

@keyframes rouletteDecoShake {
  0% {
    transform: rotate(5deg);
  }

  33.33% {
    transform: rotate(-7.5deg);
  }

  66.66% {
    transform: rotate(11.25deg);
  }

  to {
    transform: rotate(-16.875deg);
  }
}

@keyframes rouletteSpecialAward {
  0% {
    box-shadow: 0 0 0 #f304ac, inset 0 0 1px #f304ac;
  }

  to {
    box-shadow: 0 0 40px #f304ac, inset 0 0 1px #f304ac;
  }
}

@keyframes rouletteNumberTimes {
  0% {
    box-shadow: 0 0 0 0 rgba(252, 255, 0, 0.55), inset 0 0 1px rgba(252, 255, 0, 0.55);
  }

  to {
    box-shadow: 0 0 16px 2px rgba(252, 255, 0, 0.55), inset 0 0 1px rgba(252, 255, 0, 0.55);
  }
}

@keyframes rouletteNeonLight {
  0% {
    background-position: 0 50%;
  }

  to {
    background-position: 200% 50%;
  }
}

@keyframes roulettePrizeNumber {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 234, 0, 0), 0 0 0 0 rgba(255, 234, 0, 0);
  }

  to {
    box-shadow: 0 0 1.6666666667vw 0.2083333333vw rgba(255, 234, 0, 0.5), 0 0 0.6944444444vw 0.1388888889vw rgba(255, 234, 0, 0.9);
  }
}

@keyframes roulettePrizeNumber-pigSize {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 234, 0, 0), 0 0 0 0 rgba(255, 234, 0, 0);
  }

  to {
    box-shadow: 0 0 24px 3px rgba(255, 234, 0, 0.5), 0 0 10px 2px rgba(255, 234, 0, 0.9);
  }
}

@keyframes roulettePrizeNumberAward {
  0% {
    text-shadow: 0 0.0694444444vw 0 #dd3f07, 0 -0.0694444444vw 0 #dd3f07, -0.0694444444vw 0 0 #dd3f07, 0.0694444444vw 0 0 #dd3f07, 0.0694444444vw 0.0694444444vw 0 #dd3f07,
      0.0694444444vw -0.0694444444vw 0 #dd3f07, -0.0694444444vw -0.0694444444vw 0 #dd3f07, -0.0694444444vw 0.0694444444vw 0 #dd3f07, -0.0694444444vw -0.1388888889vw 0 rgba(255, 234, 0, 0),
      0 -0.1388888889vw 0 rgba(255, 234, 0, 0), 0.0694444444vw -0.1388888889vw 0 rgba(255, 234, 0, 0), 0.1388888889vw -0.1388888889vw 0 rgba(255, 234, 0, 0),
      0.1388888889vw -0.0694444444vw 0 rgba(255, 234, 0, 0), 0.1388888889vw 0 0 rgba(255, 234, 0, 0), 0.1388888889vw 0.0694444444vw 0 rgba(255, 234, 0, 0),
      0.1388888889vw 0.1388888889vw 0 rgba(255, 234, 0, 0), 0.0694444444vw 0.1388888889vw 0 rgba(255, 234, 0, 0), 0 0.1388888889vw 0 rgba(255, 234, 0, 0),
      -0.0694444444vw 0.1388888889vw 0 rgba(255, 234, 0, 0), -0.1388888889vw 0.1388888889vw 0 rgba(255, 234, 0, 0), -0.1388888889vw 0.0694444444vw 0 rgba(255, 234, 0, 0),
      -0.1388888889vw 0 0 rgba(255, 234, 0, 0), -0.1388888889vw -0.0694444444vw 0 rgba(255, 234, 0, 0), -0.1388888889vw -0.1388888889vw 0 rgba(255, 234, 0, 0);
  }

  to {
    text-shadow: 0 0.0694444444vw 0 #dd3f07, 0 -0.0694444444vw 0 #dd3f07, -0.0694444444vw 0 0 #dd3f07, 0.0694444444vw 0 0 #dd3f07, 0.0694444444vw 0.0694444444vw 0 #dd3f07,
      0.0694444444vw -0.0694444444vw 0 #dd3f07, -0.0694444444vw -0.0694444444vw 0 #dd3f07, -0.0694444444vw 0.0694444444vw 0 #dd3f07,
      -0.0694444444vw -0.1388888889vw 0.2083333333vw rgba(255, 234, 0, 0.5), 0 -0.1388888889vw 0.2083333333vw rgba(255, 234, 0, 0.5),
      0.0694444444vw -0.1388888889vw 0.2083333333vw rgba(255, 234, 0, 0.5), 0.1388888889vw -0.1388888889vw 0.2083333333vw rgba(255, 234, 0, 0.5),
      0.1388888889vw -0.0694444444vw 0.2083333333vw rgba(255, 234, 0, 0.5), 0.1388888889vw 0 0.2083333333vw rgba(255, 234, 0, 0.5), 0.1388888889vw 0.0694444444vw 0.2083333333vw rgba(255, 234, 0, 0.5),
      0.1388888889vw 0.1388888889vw 0.2083333333vw rgba(255, 234, 0, 0.5), 0.0694444444vw 0.1388888889vw 0.2083333333vw rgba(255, 234, 0, 0.5), 0 0.1388888889vw 0.2083333333vw rgba(255, 234, 0, 0.5),
      -0.0694444444vw 0.1388888889vw 0.2083333333vw rgba(255, 234, 0, 0.5), -0.1388888889vw 0.1388888889vw 0.2083333333vw rgba(255, 234, 0, 0.5),
      -0.1388888889vw 0.0694444444vw 0.2083333333vw rgba(255, 234, 0, 0.5), -0.1388888889vw 0 0.2083333333vw rgba(255, 234, 0, 0.5),
      -0.1388888889vw -0.0694444444vw 0.2083333333vw rgba(255, 234, 0, 0.5), -0.1388888889vw -0.1388888889vw 0.2083333333vw rgba(255, 234, 0, 0.5);
  }
}

@keyframes roulettePrizeNumberAward-pigSize {
  0% {
    text-shadow: 0 1px 0 #dd3f07, 0 -1px 0 #dd3f07, -1px 0 0 #dd3f07, 1px 0 0 #dd3f07, 1px 1px 0 #dd3f07, 1px -1px 0 #dd3f07, -1px -1px 0 #dd3f07, -1px 1px 0 #dd3f07, -1px -2px 0 rgba(255, 234, 0, 0),
      0 -2px 0 rgba(255, 234, 0, 0), 1px -2px 0 rgba(255, 234, 0, 0), 2px -2px 0 rgba(255, 234, 0, 0), 2px -1px 0 rgba(255, 234, 0, 0), 2px 0 0 rgba(255, 234, 0, 0), 2px 1px 0 rgba(255, 234, 0, 0),
      2px 2px 0 rgba(255, 234, 0, 0), 1px 2px 0 rgba(255, 234, 0, 0), 0 2px 0 rgba(255, 234, 0, 0), -1px 2px 0 rgba(255, 234, 0, 0), -2px 2px 0 rgba(255, 234, 0, 0), -2px 1px 0 rgba(255, 234, 0, 0),
      -2px 0 0 rgba(255, 234, 0, 0), -2px -1px 0 rgba(255, 234, 0, 0), -2px -2px 0 rgba(255, 234, 0, 0);
  }

  to {
    text-shadow: 0 1px 0 #dd3f07, 0 -1px 0 #dd3f07, -1px 0 0 #dd3f07, 1px 0 0 #dd3f07, 1px 1px 0 #dd3f07, 1px -1px 0 #dd3f07, -1px -1px 0 #dd3f07, -1px 1px 0 #dd3f07,
      -1px -2px 3px rgba(255, 234, 0, 0.5), 0 -2px 3px rgba(255, 234, 0, 0.5), 1px -2px 3px rgba(255, 234, 0, 0.5), 2px -2px 3px rgba(255, 234, 0, 0.5), 2px -1px 3px rgba(255, 234, 0, 0.5),
      2px 0 3px rgba(255, 234, 0, 0.5), 2px 1px 3px rgba(255, 234, 0, 0.5), 2px 2px 3px rgba(255, 234, 0, 0.5), 1px 2px 3px rgba(255, 234, 0, 0.5), 0 2px 3px rgba(255, 234, 0, 0.5),
      -1px 2px 3px rgba(255, 234, 0, 0.5), -2px 2px 3px rgba(255, 234, 0, 0.5), -2px 1px 3px rgba(255, 234, 0, 0.5), -2px 0 3px rgba(255, 234, 0, 0.5), -2px -1px 3px rgba(255, 234, 0, 0.5),
      -2px -2px 3px rgba(255, 234, 0, 0.5);
  }
}

@keyframes roulettePrizeNumber0Shadow {
  0% {
    text-shadow: -0.2083333333vw -0.2777777778vw 0 rgba(255, 234, 0, 0), -0.1388888889vw -0.2777777778vw 0 rgba(255, 234, 0, 0), -0.0694444444vw -0.2777777778vw 0 rgba(255, 234, 0, 0),
      0 -0.2777777778vw 0 rgba(255, 234, 0, 0), 0.0694444444vw -0.2777777778vw 0 rgba(255, 234, 0, 0), 0.1388888889vw -0.2777777778vw 0 rgba(255, 234, 0, 0),
      0.2083333333vw -0.2777777778vw 0 rgba(255, 234, 0, 0), 0.2777777778vw -0.2777777778vw 0 rgba(255, 234, 0, 0), 0.2777777778vw -0.2083333333vw 0 rgba(255, 234, 0, 0),
      0.2777777778vw -0.1388888889vw 0 rgba(255, 234, 0, 0), 0.2777777778vw -0.0694444444vw 0 rgba(255, 234, 0, 0), 0.2777777778vw 0 0 rgba(255, 234, 0, 0),
      0.2777777778vw 0.0694444444vw 0 rgba(255, 234, 0, 0), 0.2777777778vw 0.1388888889vw 0 rgba(255, 234, 0, 0), 0.2777777778vw 0.2083333333vw 0 rgba(255, 234, 0, 0),
      0.2777777778vw 0.2777777778vw 0 rgba(255, 234, 0, 0), 0.2083333333vw 0.2777777778vw 0 rgba(255, 234, 0, 0), 0.1388888889vw 0.2777777778vw 0 rgba(255, 234, 0, 0),
      0.0694444444vw 0.2777777778vw 0 rgba(255, 234, 0, 0), 0 0.2777777778vw 0 rgba(255, 234, 0, 0), -0.0694444444vw 0.2777777778vw 0 rgba(255, 234, 0, 0),
      -0.1388888889vw 0.2777777778vw 0 rgba(255, 234, 0, 0), -0.2083333333vw 0.2777777778vw 0 rgba(255, 234, 0, 0), -0.2777777778vw 0.2777777778vw 0 rgba(255, 234, 0, 0),
      -0.2777777778vw 0.2083333333vw 0 rgba(255, 234, 0, 0), -0.2777777778vw 0.1388888889vw 0 rgba(255, 234, 0, 0), -0.2777777778vw 0.0694444444vw 0 rgba(255, 234, 0, 0),
      -0.2777777778vw 0 0 rgba(255, 234, 0, 0), -0.2777777778vw -0.0694444444vw 0 rgba(255, 234, 0, 0), -0.2777777778vw -0.1388888889vw 0 rgba(255, 234, 0, 0),
      -0.2777777778vw -0.2083333333vw 0 rgba(255, 234, 0, 0), -0.2777777778vw -0.2777777778vw 0 rgba(255, 234, 0, 0);
  }

  to {
    text-shadow: -0.2083333333vw -0.2777777778vw 0.5555555556vw rgba(255, 234, 0, 0.3), -0.1388888889vw -0.2777777778vw 0.5555555556vw rgba(255, 234, 0, 0.3),
      -0.0694444444vw -0.2777777778vw 0.5555555556vw rgba(255, 234, 0, 0.3), 0 -0.2777777778vw 0.5555555556vw rgba(255, 234, 0, 0.3),
      0.0694444444vw -0.2777777778vw 0.5555555556vw rgba(255, 234, 0, 0.3), 0.1388888889vw -0.2777777778vw 0.5555555556vw rgba(255, 234, 0, 0.3),
      0.2083333333vw -0.2777777778vw 0.5555555556vw rgba(255, 234, 0, 0.3), 0.2777777778vw -0.2777777778vw 0.5555555556vw rgba(255, 234, 0, 0.3),
      0.2777777778vw -0.2083333333vw 0.5555555556vw rgba(255, 234, 0, 0.3), 0.2777777778vw -0.1388888889vw 0.5555555556vw rgba(255, 234, 0, 0.3),
      0.2777777778vw -0.0694444444vw 0.5555555556vw rgba(255, 234, 0, 0.3), 0.2777777778vw 0 0.5555555556vw rgba(255, 234, 0, 0.3), 0.2777777778vw 0.0694444444vw 0.5555555556vw rgba(255, 234, 0, 0.3),
      0.2777777778vw 0.1388888889vw 0.5555555556vw rgba(255, 234, 0, 0.3), 0.2777777778vw 0.2083333333vw 0.5555555556vw rgba(255, 234, 0, 0.3),
      0.2777777778vw 0.2777777778vw 0.5555555556vw rgba(255, 234, 0, 0.3), 0.2083333333vw 0.2777777778vw 0.5555555556vw rgba(255, 234, 0, 0.3),
      0.1388888889vw 0.2777777778vw 0.5555555556vw rgba(255, 234, 0, 0.3), 0.0694444444vw 0.2777777778vw 0.5555555556vw rgba(255, 234, 0, 0.3), 0 0.2777777778vw 0.5555555556vw rgba(255, 234, 0, 0.3),
      -0.0694444444vw 0.2777777778vw 0.5555555556vw rgba(255, 234, 0, 0.3), -0.1388888889vw 0.2777777778vw 0.5555555556vw rgba(255, 234, 0, 0.3),
      -0.2083333333vw 0.2777777778vw 0.5555555556vw rgba(255, 234, 0, 0.3), -0.2777777778vw 0.2777777778vw 0.5555555556vw rgba(255, 234, 0, 0.3),
      -0.2777777778vw 0.2083333333vw 0.5555555556vw rgba(255, 234, 0, 0.3), -0.2777777778vw 0.1388888889vw 0.5555555556vw rgba(255, 234, 0, 0.3),
      -0.2777777778vw 0.0694444444vw 0.5555555556vw rgba(255, 234, 0, 0.3), -0.2777777778vw 0 0.5555555556vw rgba(255, 234, 0, 0.3),
      -0.2777777778vw -0.0694444444vw 0.5555555556vw rgba(255, 234, 0, 0.3), -0.2777777778vw -0.1388888889vw 0.5555555556vw rgba(255, 234, 0, 0.3),
      -0.2777777778vw -0.2083333333vw 0.5555555556vw rgba(255, 234, 0, 0.3), -0.2777777778vw -0.2777777778vw 0.5555555556vw rgba(255, 234, 0, 0.3);
  }
}

@keyframes roulettePrizeNumber0Shadow-pigSize {
  0% {
    text-shadow: -3px -4px 0 rgba(255, 234, 0, 0), -2px -4px 0 rgba(255, 234, 0, 0), -1px -4px 0 rgba(255, 234, 0, 0), 0 -4px 0 rgba(255, 234, 0, 0), 1px -4px 0 rgba(255, 234, 0, 0),
      2px -4px 0 rgba(255, 234, 0, 0), 3px -4px 0 rgba(255, 234, 0, 0), 4px -4px 0 rgba(255, 234, 0, 0), 4px -3px 0 rgba(255, 234, 0, 0), 4px -2px 0 rgba(255, 234, 0, 0),
      4px -1px 0 rgba(255, 234, 0, 0), 4px 0 0 rgba(255, 234, 0, 0), 4px 1px 0 rgba(255, 234, 0, 0), 4px 2px 0 rgba(255, 234, 0, 0), 4px 3px 0 rgba(255, 234, 0, 0), 4px 4px 0 rgba(255, 234, 0, 0),
      3px 4px 0 rgba(255, 234, 0, 0), 2px 4px 0 rgba(255, 234, 0, 0), 1px 4px 0 rgba(255, 234, 0, 0), 0 4px 0 rgba(255, 234, 0, 0), -1px 4px 0 rgba(255, 234, 0, 0), -2px 4px 0 rgba(255, 234, 0, 0),
      -3px 4px 0 rgba(255, 234, 0, 0), -4px 4px 0 rgba(255, 234, 0, 0), -4px 3px 0 rgba(255, 234, 0, 0), -4px 2px 0 rgba(255, 234, 0, 0), -4px 1px 0 rgba(255, 234, 0, 0), -4px 0 0 rgba(255, 234, 0, 0),
      -4px -1px 0 rgba(255, 234, 0, 0), -4px -2px 0 rgba(255, 234, 0, 0), -4px -3px 0 rgba(255, 234, 0, 0), -4px -4px 0 rgba(255, 234, 0, 0);
  }

  to {
    text-shadow: -3px -4px 8px rgba(255, 234, 0, 0.3), -2px -4px 8px rgba(255, 234, 0, 0.3), -1px -4px 8px rgba(255, 234, 0, 0.3), 0 -4px 8px rgba(255, 234, 0, 0.3),
      1px -4px 8px rgba(255, 234, 0, 0.3), 2px -4px 8px rgba(255, 234, 0, 0.3), 3px -4px 8px rgba(255, 234, 0, 0.3), 4px -4px 8px rgba(255, 234, 0, 0.3), 4px -3px 8px rgba(255, 234, 0, 0.3),
      4px -2px 8px rgba(255, 234, 0, 0.3), 4px -1px 8px rgba(255, 234, 0, 0.3), 4px 0 8px rgba(255, 234, 0, 0.3), 4px 1px 8px rgba(255, 234, 0, 0.3), 4px 2px 8px rgba(255, 234, 0, 0.3),
      4px 3px 8px rgba(255, 234, 0, 0.3), 4px 4px 8px rgba(255, 234, 0, 0.3), 3px 4px 8px rgba(255, 234, 0, 0.3), 2px 4px 8px rgba(255, 234, 0, 0.3), 1px 4px 8px rgba(255, 234, 0, 0.3),
      0 4px 8px rgba(255, 234, 0, 0.3), -1px 4px 8px rgba(255, 234, 0, 0.3), -2px 4px 8px rgba(255, 234, 0, 0.3), -3px 4px 8px rgba(255, 234, 0, 0.3), -4px 4px 8px rgba(255, 234, 0, 0.3),
      -4px 3px 8px rgba(255, 234, 0, 0.3), -4px 2px 8px rgba(255, 234, 0, 0.3), -4px 1px 8px rgba(255, 234, 0, 0.3), -4px 0 8px rgba(255, 234, 0, 0.3), -4px -1px 8px rgba(255, 234, 0, 0.3),
      -4px -2px 8px rgba(255, 234, 0, 0.3), -4px -3px 8px rgba(255, 234, 0, 0.3), -4px -4px 8px rgba(255, 234, 0, 0.3);
  }
}

@keyframes roulettePrizeNumberText {
  0% {
    text-shadow: -0.2083333333vw -0.2777777778vw 0 #dd3f07, -0.1388888889vw -0.2777777778vw 0 #dd3f07, -0.0694444444vw -0.2777777778vw 0 #dd3f07, 0 -0.2777777778vw 0 #dd3f07,
      0.0694444444vw -0.2777777778vw 0 #dd3f07, 0.1388888889vw -0.2777777778vw 0 #dd3f07, 0.2083333333vw -0.2777777778vw 0 #dd3f07, 0.2777777778vw -0.2777777778vw 0 #dd3f07,
      0.2777777778vw -0.2083333333vw 0 #dd3f07, 0.2777777778vw -0.1388888889vw 0 #dd3f07, 0.2777777778vw -0.0694444444vw 0 #dd3f07, 0.2777777778vw 0 0 #dd3f07, 0.2777777778vw 0.0694444444vw 0 #dd3f07,
      0.2777777778vw 0.1388888889vw 0 #dd3f07, 0.2777777778vw 0.2083333333vw 0 #dd3f07, 0.2777777778vw 0.2777777778vw 0 #dd3f07, 0.2083333333vw 0.2777777778vw 0 #dd3f07,
      0.1388888889vw 0.2777777778vw 0 #dd3f07, 0.0694444444vw 0.2777777778vw 0 #dd3f07, 0 0.2777777778vw 0 #dd3f07, -0.0694444444vw 0.2777777778vw 0 #dd3f07, -0.1388888889vw 0.2777777778vw 0 #dd3f07,
      -0.2083333333vw 0.2777777778vw 0 #dd3f07, -0.2777777778vw 0.2777777778vw 0 #dd3f07, -0.2777777778vw 0.2083333333vw 0 #dd3f07, -0.2777777778vw 0.1388888889vw 0 #dd3f07,
      -0.2777777778vw 0.0694444444vw 0 #dd3f07, -0.2777777778vw 0 0 #dd3f07, -0.2777777778vw -0.0694444444vw 0 #dd3f07, -0.2777777778vw -0.1388888889vw 0 #dd3f07,
      -0.2777777778vw -0.2083333333vw 0 #dd3f07, -0.2777777778vw -0.2777777778vw 0 #dd3f07, -0.3472222222vw -0.4166666667vw 0 rgba(255, 234, 0, 0),
      -0.2777777778vw -0.4166666667vw 0 rgba(255, 234, 0, 0), -0.2083333333vw -0.4166666667vw 0 rgba(255, 234, 0, 0), -0.1388888889vw -0.4166666667vw 0 rgba(255, 234, 0, 0),
      -0.0694444444vw -0.4166666667vw 0 rgba(255, 234, 0, 0), 0 -0.4166666667vw 0 rgba(255, 234, 0, 0), 0.0694444444vw -0.4166666667vw 0 rgba(255, 234, 0, 0),
      0.1388888889vw -0.4166666667vw 0 rgba(255, 234, 0, 0), 0.2083333333vw -0.4166666667vw 0 rgba(255, 234, 0, 0), 0.2777777778vw -0.4166666667vw 0 rgba(255, 234, 0, 0),
      0.3472222222vw -0.4166666667vw 0 rgba(255, 234, 0, 0), 0.4166666667vw -0.4166666667vw 0 rgba(255, 234, 0, 0), 0.4166666667vw -0.3472222222vw 0 rgba(255, 234, 0, 0),
      0.4166666667vw -0.2777777778vw 0 rgba(255, 234, 0, 0), 0.4166666667vw -0.2083333333vw 0 rgba(255, 234, 0, 0), 0.4166666667vw -0.1388888889vw 0 rgba(255, 234, 0, 0),
      0.4166666667vw -0.0694444444vw 0 rgba(255, 234, 0, 0), 0.4166666667vw 0 0 rgba(255, 234, 0, 0), 0.0694444444vw 0.4166666667vw 0 rgba(255, 234, 0, 0),
      0.1388888889vw 0.4166666667vw 0 rgba(255, 234, 0, 0), 0.2083333333vw 0.4166666667vw 0 rgba(255, 234, 0, 0), 0.2777777778vw 0.4166666667vw 0 rgba(255, 234, 0, 0),
      0.3472222222vw 0.4166666667vw 0 rgba(255, 234, 0, 0), 0.4166666667vw 0.4166666667vw 0 rgba(255, 234, 0, 0), 0.3472222222vw 0.4166666667vw 0 rgba(255, 234, 0, 0),
      0.2777777778vw 0.4166666667vw 0 rgba(255, 234, 0, 0), 0.2083333333vw 0.4166666667vw 0 rgba(255, 234, 0, 0), 0.1388888889vw 0.4166666667vw 0 rgba(255, 234, 0, 0),
      0.0694444444vw 0.4166666667vw 0 rgba(255, 234, 0, 0), 0 0.4166666667vw 0 rgba(255, 234, 0, 0), -0.0694444444vw 0.4166666667vw 0 rgba(255, 234, 0, 0),
      -0.1388888889vw 0.4166666667vw 0 rgba(255, 234, 0, 0), -0.2083333333vw 0.4166666667vw 0 rgba(255, 234, 0, 0), -0.2777777778vw 0.4166666667vw 0 rgba(255, 234, 0, 0),
      -0.3472222222vw 0.4166666667vw 0 rgba(255, 234, 0, 0), -0.4166666667vw 0.4166666667vw 0 rgba(255, 234, 0, 0), -0.4166666667vw 0.3472222222vw 0 rgba(255, 234, 0, 0),
      -0.4166666667vw 0.2777777778vw 0 rgba(255, 234, 0, 0), -0.4166666667vw 0.2083333333vw 0 rgba(255, 234, 0, 0), -0.4166666667vw 0.1388888889vw 0 rgba(255, 234, 0, 0),
      -0.4166666667vw 0.0694444444vw 0 rgba(255, 234, 0, 0), -0.4166666667vw 0 0 rgba(255, 234, 0, 0), -0.4166666667vw -0.0694444444vw 0 rgba(255, 234, 0, 0),
      -0.4166666667vw -0.1388888889vw 0 rgba(255, 234, 0, 0), -0.4166666667vw -0.2083333333vw 0 rgba(255, 234, 0, 0), -0.4166666667vw -0.2777777778vw 0 rgba(255, 234, 0, 0),
      -0.4166666667vw -0.3472222222vw 0 rgba(255, 234, 0, 0), -0.4166666667vw -0.4166666667vw 0 rgba(255, 234, 0, 0);
  }

  to {
    text-shadow: -0.2777777778vw -0.2777777778vw 0 #dd3f07, -0.2083333333vw -0.2777777778vw 0 #dd3f07, -0.1388888889vw -0.2777777778vw 0 #dd3f07, -0.0694444444vw -0.2777777778vw 0 #dd3f07,
      0 -0.2777777778vw 0 #dd3f07, 0.0694444444vw -0.2777777778vw 0 #dd3f07, 0.1388888889vw -0.2777777778vw 0 #dd3f07, 0.2083333333vw -0.2777777778vw 0 #dd3f07,
      0.2777777778vw -0.2777777778vw 0 #dd3f07, 0.2777777778vw -0.2083333333vw 0 #dd3f07, 0.2777777778vw -0.1388888889vw 0 #dd3f07, 0.2777777778vw -0.0694444444vw 0 #dd3f07, 0.2777777778vw 0 0 #dd3f07,
      0.2777777778vw 0.0694444444vw 0 #dd3f07, 0.2777777778vw 0.1388888889vw 0 #dd3f07, 0.2777777778vw 0.2083333333vw 0 #dd3f07, 0.2777777778vw 0.2777777778vw 0 #dd3f07,
      0.2083333333vw 0.2777777778vw 0 #dd3f07, 0.1388888889vw 0.2777777778vw 0 #dd3f07, 0.0694444444vw 0.2777777778vw 0 #dd3f07, 0 0.2777777778vw 0 #dd3f07, -0.0694444444vw 0.2777777778vw 0 #dd3f07,
      -0.1388888889vw 0.2777777778vw 0 #dd3f07, -0.2083333333vw 0.2777777778vw 0 #dd3f07, -0.2777777778vw 0.2777777778vw 0 #dd3f07, -0.2777777778vw 0.2083333333vw 0 #dd3f07,
      -0.2777777778vw 0.1388888889vw 0 #dd3f07, -0.2777777778vw 0.0694444444vw 0 #dd3f07, -0.2777777778vw 0 0 #dd3f07, -0.2777777778vw -0.0694444444vw 0 #dd3f07,
      -0.2777777778vw -0.1388888889vw 0 #dd3f07, -0.2777777778vw -0.2083333333vw 0 #dd3f07, -0.2777777778vw -0.2777777778vw 0 #dd3f07,
      -0.3472222222vw -0.4166666667vw 0.4166666667vw rgba(255, 234, 0, 0.25), -0.2777777778vw -0.4166666667vw 0.4166666667vw rgba(255, 234, 0, 0.25),
      -0.2083333333vw -0.4166666667vw 0.4166666667vw rgba(255, 234, 0, 0.25), -0.1388888889vw -0.4166666667vw 0.4166666667vw rgba(255, 234, 0, 0.25),
      -0.0694444444vw -0.4166666667vw 0.4166666667vw rgba(255, 234, 0, 0.25), 0 -0.4166666667vw 0.4166666667vw rgba(255, 234, 0, 0.25),
      0.0694444444vw -0.4166666667vw 0.4166666667vw rgba(255, 234, 0, 0.25), 0.1388888889vw -0.4166666667vw 0.4166666667vw rgba(255, 234, 0, 0.25),
      0.2083333333vw -0.4166666667vw 0.4166666667vw rgba(255, 234, 0, 0.25), 0.2777777778vw -0.4166666667vw 0.4166666667vw rgba(255, 234, 0, 0.25),
      0.3472222222vw -0.4166666667vw 0.4166666667vw rgba(255, 234, 0, 0.25), 0.4166666667vw -0.4166666667vw 0.4166666667vw rgba(255, 234, 0, 0.25),
      0.4166666667vw -0.3472222222vw 0.4166666667vw rgba(255, 234, 0, 0.25), 0.4166666667vw -0.2777777778vw 0.4166666667vw rgba(255, 234, 0, 0.25),
      0.4166666667vw -0.2083333333vw 0.4166666667vw rgba(255, 234, 0, 0.25), 0.4166666667vw -0.1388888889vw 0.4166666667vw rgba(255, 234, 0, 0.25),
      0.4166666667vw -0.0694444444vw 0.4166666667vw rgba(255, 234, 0, 0.25), 0.4166666667vw 0 0.4166666667vw rgba(255, 234, 0, 0.25),
      0.0694444444vw 0.4166666667vw 0.4166666667vw rgba(255, 234, 0, 0.25), 0.1388888889vw 0.4166666667vw 0.4166666667vw rgba(255, 234, 0, 0.25),
      0.2083333333vw 0.4166666667vw 0.4166666667vw rgba(255, 234, 0, 0.25), 0.2777777778vw 0.4166666667vw 0.4166666667vw rgba(255, 234, 0, 0.25),
      0.3472222222vw 0.4166666667vw 0.4166666667vw rgba(255, 234, 0, 0.25), 0.4166666667vw 0.4166666667vw 0.4166666667vw rgba(255, 234, 0, 0.25),
      0.3472222222vw 0.4166666667vw 0.4166666667vw rgba(255, 234, 0, 0.25), 0.2777777778vw 0.4166666667vw 0.4166666667vw rgba(255, 234, 0, 0.25),
      0.2083333333vw 0.4166666667vw 0.4166666667vw rgba(255, 234, 0, 0.25), 0.1388888889vw 0.4166666667vw 0.4166666667vw rgba(255, 234, 0, 0.25),
      0.0694444444vw 0.4166666667vw 0.4166666667vw rgba(255, 234, 0, 0.25), 0 0.4166666667vw 0.4166666667vw rgba(255, 234, 0, 0.25),
      -0.0694444444vw 0.4166666667vw 0.4166666667vw rgba(255, 234, 0, 0.25), -0.1388888889vw 0.4166666667vw 0.4166666667vw rgba(255, 234, 0, 0.25),
      -0.2083333333vw 0.4166666667vw 0.4166666667vw rgba(255, 234, 0, 0.25), -0.2777777778vw 0.4166666667vw 0.4166666667vw rgba(255, 234, 0, 0.25),
      -0.3472222222vw 0.4166666667vw 0.4166666667vw rgba(255, 234, 0, 0.25), -0.4166666667vw 0.4166666667vw 0.4166666667vw rgba(255, 234, 0, 0.25),
      -0.4166666667vw 0.3472222222vw 0.4166666667vw rgba(255, 234, 0, 0.25), -0.4166666667vw 0.2777777778vw 0.4166666667vw rgba(255, 234, 0, 0.25),
      -0.4166666667vw 0.2083333333vw 0.4166666667vw rgba(255, 234, 0, 0.25), -0.4166666667vw 0.1388888889vw 0.4166666667vw rgba(255, 234, 0, 0.25),
      -0.4166666667vw 0.0694444444vw 0.4166666667vw rgba(255, 234, 0, 0.25), -0.4166666667vw 0 0.4166666667vw rgba(255, 234, 0, 0.25),
      -0.4166666667vw -0.0694444444vw 0.4166666667vw rgba(255, 234, 0, 0.25), -0.4166666667vw -0.1388888889vw 0.4166666667vw rgba(255, 234, 0, 0.25),
      -0.4166666667vw -0.2083333333vw 0.4166666667vw rgba(255, 234, 0, 0.25), -0.4166666667vw -0.2777777778vw 0.4166666667vw rgba(255, 234, 0, 0.25),
      -0.4166666667vw -0.3472222222vw 0.4166666667vw rgba(255, 234, 0, 0.25), -0.4166666667vw -0.4166666667vw 0.4166666667vw rgba(255, 234, 0, 0.25);
  }
}

@keyframes roulettePrizeNumberText-pigSize {
  0% {
    text-shadow: -3px -4px 0 #dd3f07, -2px -4px 0 #dd3f07, -1px -4px 0 #dd3f07, 0 -4px 0 #dd3f07, 1px -4px 0 #dd3f07, 2px -4px 0 #dd3f07, 3px -4px 0 #dd3f07, 4px -4px 0 #dd3f07, 4px -3px 0 #dd3f07,
      4px -2px 0 #dd3f07, 4px -1px 0 #dd3f07, 4px 0 0 #dd3f07, 4px 1px 0 #dd3f07, 4px 2px 0 #dd3f07, 4px 3px 0 #dd3f07, 4px 4px 0 #dd3f07, 3px 4px 0 #dd3f07, 2px 4px 0 #dd3f07, 1px 4px 0 #dd3f07,
      0 4px 0 #dd3f07, -1px 4px 0 #dd3f07, -2px 4px 0 #dd3f07, -3px 4px 0 #dd3f07, -4px 4px 0 #dd3f07, -4px 3px 0 #dd3f07, -4px 2px 0 #dd3f07, -4px 1px 0 #dd3f07, -4px 0 0 #dd3f07, -4px -1px 0 #dd3f07,
      -4px -2px 0 #dd3f07, -4px -3px 0 #dd3f07, -4px -4px 0 #dd3f07, -5px -6px 0 rgba(255, 234, 0, 0), -4px -6px 0 rgba(255, 234, 0, 0), -3px -6px 0 rgba(255, 234, 0, 0),
      -2px -6px 0 rgba(255, 234, 0, 0), -1px -6px 0 rgba(255, 234, 0, 0), 0 -6px 0 rgba(255, 234, 0, 0), 1px -6px 0 rgba(255, 234, 0, 0), 2px -6px 0 rgba(255, 234, 0, 0),
      3px -6px 0 rgba(255, 234, 0, 0), 4px -6px 0 rgba(255, 234, 0, 0), 5px -6px 0 rgba(255, 234, 0, 0), 6px -6px 0 rgba(255, 234, 0, 0), 6px -5px 0 rgba(255, 234, 0, 0),
      6px -4px 0 rgba(255, 234, 0, 0), 6px -3px 0 rgba(255, 234, 0, 0), 6px -2px 0 rgba(255, 234, 0, 0), 6px -1px 0 rgba(255, 234, 0, 0), 6px 0 0 rgba(255, 234, 0, 0), 1px 6px 0 rgba(255, 234, 0, 0),
      2px 6px 0 rgba(255, 234, 0, 0), 3px 6px 0 rgba(255, 234, 0, 0), 4px 6px 0 rgba(255, 234, 0, 0), 5px 6px 0 rgba(255, 234, 0, 0), 6px 6px 0 rgba(255, 234, 0, 0), 5px 6px 0 rgba(255, 234, 0, 0),
      4px 6px 0 rgba(255, 234, 0, 0), 3px 6px 0 rgba(255, 234, 0, 0), 2px 6px 0 rgba(255, 234, 0, 0), 1px 6px 0 rgba(255, 234, 0, 0), 0 6px 0 rgba(255, 234, 0, 0), -1px 6px 0 rgba(255, 234, 0, 0),
      -2px 6px 0 rgba(255, 234, 0, 0), -3px 6px 0 rgba(255, 234, 0, 0), -4px 6px 0 rgba(255, 234, 0, 0), -5px 6px 0 rgba(255, 234, 0, 0), -6px 6px 0 rgba(255, 234, 0, 0),
      -6px 5px 0 rgba(255, 234, 0, 0), -6px 4px 0 rgba(255, 234, 0, 0), -6px 3px 0 rgba(255, 234, 0, 0), -6px 2px 0 rgba(255, 234, 0, 0), -6px 1px 0 rgba(255, 234, 0, 0), -6px 0 0 rgba(255, 234, 0, 0),
      -6px -1px 0 rgba(255, 234, 0, 0), -6px -2px 0 rgba(255, 234, 0, 0), -6px -3px 0 rgba(255, 234, 0, 0), -6px -4px 0 rgba(255, 234, 0, 0), -6px -5px 0 rgba(255, 234, 0, 0),
      -6px -6px 0 rgba(255, 234, 0, 0);
  }

  to {
    text-shadow: -4px -4px 0 #dd3f07, -3px -4px 0 #dd3f07, -2px -4px 0 #dd3f07, -1px -4px 0 #dd3f07, 0 -4px 0 #dd3f07, 1px -4px 0 #dd3f07, 2px -4px 0 #dd3f07, 3px -4px 0 #dd3f07, 4px -4px 0 #dd3f07,
      4px -3px 0 #dd3f07, 4px -2px 0 #dd3f07, 4px -1px 0 #dd3f07, 4px 0 0 #dd3f07, 4px 1px 0 #dd3f07, 4px 2px 0 #dd3f07, 4px 3px 0 #dd3f07, 4px 4px 0 #dd3f07, 3px 4px 0 #dd3f07, 2px 4px 0 #dd3f07,
      1px 4px 0 #dd3f07, 0 4px 0 #dd3f07, -1px 4px 0 #dd3f07, -2px 4px 0 #dd3f07, -3px 4px 0 #dd3f07, -4px 4px 0 #dd3f07, -4px 3px 0 #dd3f07, -4px 2px 0 #dd3f07, -4px 1px 0 #dd3f07, -4px 0 0 #dd3f07,
      -4px -1px 0 #dd3f07, -4px -2px 0 #dd3f07, -4px -3px 0 #dd3f07, -4px -4px 0 #dd3f07, -5px -6px 6px rgba(255, 234, 0, 0.25), -4px -6px 6px rgba(255, 234, 0, 0.25),
      -3px -6px 6px rgba(255, 234, 0, 0.25), -2px -6px 6px rgba(255, 234, 0, 0.25), -1px -6px 6px rgba(255, 234, 0, 0.25), 0 -6px 6px rgba(255, 234, 0, 0.25), 1px -6px 6px rgba(255, 234, 0, 0.25),
      2px -6px 6px rgba(255, 234, 0, 0.25), 3px -6px 6px rgba(255, 234, 0, 0.25), 4px -6px 6px rgba(255, 234, 0, 0.25), 5px -6px 6px rgba(255, 234, 0, 0.25), 6px -6px 6px rgba(255, 234, 0, 0.25),
      6px -5px 6px rgba(255, 234, 0, 0.25), 6px -4px 6px rgba(255, 234, 0, 0.25), 6px -3px 6px rgba(255, 234, 0, 0.25), 6px -2px 6px rgba(255, 234, 0, 0.25), 6px -1px 6px rgba(255, 234, 0, 0.25),
      6px 0 6px rgba(255, 234, 0, 0.25), 1px 6px 6px rgba(255, 234, 0, 0.25), 2px 6px 6px rgba(255, 234, 0, 0.25), 3px 6px 6px rgba(255, 234, 0, 0.25), 4px 6px 6px rgba(255, 234, 0, 0.25),
      5px 6px 6px rgba(255, 234, 0, 0.25), 6px 6px 6px rgba(255, 234, 0, 0.25), 5px 6px 6px rgba(255, 234, 0, 0.25), 4px 6px 6px rgba(255, 234, 0, 0.25), 3px 6px 6px rgba(255, 234, 0, 0.25),
      2px 6px 6px rgba(255, 234, 0, 0.25), 1px 6px 6px rgba(255, 234, 0, 0.25), 0 6px 6px rgba(255, 234, 0, 0.25), -1px 6px 6px rgba(255, 234, 0, 0.25), -2px 6px 6px rgba(255, 234, 0, 0.25),
      -3px 6px 6px rgba(255, 234, 0, 0.25), -4px 6px 6px rgba(255, 234, 0, 0.25), -5px 6px 6px rgba(255, 234, 0, 0.25), -6px 6px 6px rgba(255, 234, 0, 0.25), -6px 5px 6px rgba(255, 234, 0, 0.25),
      -6px 4px 6px rgba(255, 234, 0, 0.25), -6px 3px 6px rgba(255, 234, 0, 0.25), -6px 2px 6px rgba(255, 234, 0, 0.25), -6px 1px 6px rgba(255, 234, 0, 0.25), -6px 0 6px rgba(255, 234, 0, 0.25),
      -6px -1px 6px rgba(255, 234, 0, 0.25), -6px -2px 6px rgba(255, 234, 0, 0.25), -6px -3px 6px rgba(255, 234, 0, 0.25), -6px -4px 6px rgba(255, 234, 0, 0.25), -6px -5px 6px rgba(255, 234, 0, 0.25),
      -6px -6px 6px rgba(255, 234, 0, 0.25);
  }
}

@keyframes rouletteMainBoxPart {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(1turn);
  }
}

@keyframes rouletteMainBoxBtnShadow {
  0% {
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.3), 0 2px 3px 1px rgba(0, 0, 0, 0.35), inset -1px 0 1px rgba(0, 0, 0, 0.5), inset 1px 1px 1px #fff;
  }

  to {
    box-shadow: 0 0 48px rgba(0, 0, 0, 0.9), 0 2px 3px 1px rgba(0, 0, 0, 0.35), inset -1px 0 1px rgba(0, 0, 0, 0.5), inset 1px 1px 1px #fff;
  }
}

@keyframes rouletteMainBoxBtnLight {
  0% {
    transform: translate3d(-60%, 0, 0) rotate(-45deg);
  }

  to {
    transform: translate3d(60%, 0, 0) rotate(-45deg);
  }
}

@keyframes rouletteBoxCoin {
  0% {
    opacity: 0.5;
    transform: scale3d(0.6, 0.6, 1);
  }

  25% {
    opacity: 0.8;
    transform: scale3d(0.8, 0.8, 1);
  }

  50% {
    opacity: 1;
    transform: scaleX(1);
  }

  75% {
    opacity: 0.6;
    transform: scale3d(1.15, 1.15, 1);
  }

  to {
    opacity: 0.1;
    transform: scale3d(1.3, 1.3, 1);
  }
}

@keyframes rouletteMain_P6789_N0 {
  0% {
    transform: rotate(-2turn);
  }

  to {
    transform: rotate(0deg);
  }
}

@keyframes rouletteMain_P6_N1 {
  0% {
    transform: rotate(-780deg);
  }

  to {
    transform: rotate(-60deg);
  }
}

@keyframes rouletteMain_P6_N2_P9_N3 {
  0% {
    transform: rotate(-840deg);
  }

  to {
    transform: rotate(-120deg);
  }
}

@keyframes rouletteMain_P6_N3_P8_N4 {
  0% {
    transform: rotate(-900deg);
  }

  to {
    transform: rotate(-180deg);
  }
}

@keyframes rouletteMain_P6_N4_P9_N6 {
  0% {
    transform: rotate(-960deg);
  }

  to {
    transform: rotate(-240deg);
  }
}

@keyframes rouletteMain_P6_N5 {
  0% {
    transform: rotate(-1020deg);
  }

  to {
    transform: rotate(-300deg);
  }
}

@keyframes rouletteMain_P7_N1 {
  0% {
    transform: rotate(-771.4285714286deg);
  }

  to {
    transform: rotate(-51.4285714286deg);
  }
}

@keyframes rouletteMain_P7_N2 {
  0% {
    transform: rotate(-822.8571428571deg);
  }

  to {
    transform: rotate(-102.8571428571deg);
  }
}

@keyframes rouletteMain_P7_N3 {
  0% {
    transform: rotate(-874.2857142857deg);
  }

  to {
    transform: rotate(-154.2857142857deg);
  }
}

@keyframes rouletteMain_P7_N4 {
  0% {
    transform: rotate(-925.7142857143deg);
  }

  to {
    transform: rotate(-205.7142857143deg);
  }
}

@keyframes rouletteMain_P7_N5 {
  0% {
    transform: rotate(-977.1428571429deg);
  }

  to {
    transform: rotate(-257.1428571429deg);
  }
}

@keyframes rouletteMain_P7_N6 {
  0% {
    transform: rotate(-1028.5714285714deg);
  }

  to {
    transform: rotate(-308.5714285714deg);
  }
}

@keyframes rouletteMain_P8_N1 {
  0% {
    transform: rotate(-765deg);
  }

  to {
    transform: rotate(-45deg);
  }
}

@keyframes rouletteMain_P8_N2 {
  0% {
    transform: rotate(-810deg);
  }

  to {
    transform: rotate(-90deg);
  }
}

@keyframes rouletteMain_P8_N3 {
  0% {
    transform: rotate(-855deg);
  }

  to {
    transform: rotate(-135deg);
  }
}

@keyframes rouletteMain_P8_N5 {
  0% {
    transform: rotate(-945deg);
  }

  to {
    transform: rotate(-225deg);
  }
}

@keyframes rouletteMain_P8_N6 {
  0% {
    transform: rotate(-990deg);
  }

  to {
    transform: rotate(-270deg);
  }
}

@keyframes rouletteMain_P8_N7 {
  0% {
    transform: rotate(-1035deg);
  }

  to {
    transform: rotate(-315deg);
  }
}

@keyframes rouletteMain_P9_N1 {
  0% {
    transform: rotate(-760deg);
  }

  to {
    transform: rotate(-40deg);
  }
}

@keyframes rouletteMain_P9_N2 {
  0% {
    transform: rotate(-800deg);
  }

  to {
    transform: rotate(-80deg);
  }
}

@keyframes rouletteMain_P9_N4 {
  0% {
    transform: rotate(-880deg);
  }

  to {
    transform: rotate(-160deg);
  }
}

@keyframes rouletteMain_P9_N5 {
  0% {
    transform: rotate(-920deg);
  }

  to {
    transform: rotate(-200deg);
  }
}

@keyframes rouletteMain_P9_N7 {
  0% {
    transform: rotate(-1000deg);
  }

  to {
    transform: rotate(-280deg);
  }
}

@keyframes rouletteMain_P9_N8 {
  0% {
    transform: rotate(-1040deg);
  }

  to {
    transform: rotate(-320deg);
  }
}

@font-face {
  font-family: rouletteStar;
  src: url(https://csi.20icipp.com/img/static/roulette/fonts/icomoon.eot?p6x2l0=);
  src: url(https://csi.20icipp.com/img/static/roulette/fonts/icomoon.ttf?p6x2l0=) format('truetype');
}

.rouletteMain,
.rouletteMain:after,
.rouletteMain:before,
.rouletteMain_awards_item.special .rouletteMain_awards_medals,
.rouletteMain_awards_item > div,
.rouletteMain_box_wrap:before,
.rouletteMain_winner_wrap:after,
.rouletteMain_winner_wrap:before,
.rouletteMain_wrap:after,
.rouletteMain_wrap:before {
  background-size: 100% 100%;
}

.rouletteMain,
.rouletteMain_awards_item,
.rouletteMain_box,
.rouletteMain_winner,
.rouletteMain_winner_wrap:after,
.rouletteMain_winner_wrap:before {
  height: 0;
}

.rouletteMain_winner_wrap:after,
.rouletteMain_winner_wrap:before {
  content: '';
  position: absolute;
}

.rouletteMain:after,
.rouletteMain:before,
.rouletteMain_awards_item > div,
.rouletteMain_box_coinWrap,
.rouletteMain_box_numberTimes > div,
.rouletteMain_box_wrap,
.rouletteMain_box_wrap:after,
.rouletteMain_box_wrap:before,
.rouletteMain_winner_wrap,
.rouletteMain_wrap {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.roulette {
  /*font-family: Arial, Helvetica, Microsoft YaHei, Microsoft JhengHei, PingFangTC, sans-serif;*/
  overflow: hidden;
  width: 100%;
}

.roulette * {
  box-sizing: border-box;
}

.rouletteMain {
  background-image: url(https://s3.ap-southeast-1.amazonaws.com/media-dev.ezgame4u.com/cms/babu/image/6225b4f336723.png);
  margin: 0 auto;
  max-width: 1440px;
  padding-bottom: 57.6388888889%;
  position: relative;
  width: 100%;
}

.rouletteMain:after,
.rouletteMain:before {
  animation: rouletteDecoShake 25s infinite;
  content: '';
}

.rouletteMain:before {
  animation-direction: alternate;
  animation-timing-function: linear;
  background-image: url(https://csi.20icipp.com/img/static/roulette/roulette_balloon.png);
}

.rouletteMain:after {
  animation-direction: alternate-reverse;
  background-image: url(https://csi.20icipp.com/img/static/roulette/roulette_ball.png);
}

.rouletteMain_wrap:after,
.rouletteMain_wrap:before {
  background-image: url(https://s3.ap-southeast-1.amazonaws.com/media-dev.ezgame4u.com/cms/babu/image/6225b4f336723.png);
  content: '';
  height: 100%;
  position: absolute;
  top: 0;
  transform: rotateY(180deg);
  width: 100%;
}

.rouletteMain_wrap:before {
  left: -100%;
}

.rouletteMain_wrap:after {
  left: 100%;
}

.rouletteMain_head {
  display: flex;
  justify-content: center;
  margin-bottom: 3.4722222222%;
  padding: 6px 0;
  position: relative;
  z-index: 1;
}

.rouletteMain_item + .rouletteMain_item {
  margin-left: 36px;
}

.rouletteMain_item > a {
  color: #fff;
  display: block;
  font-size: 20px;
  line-height: 48px;
  padding: 0 3px;
  position: relative;
  text-decoration: none;
}

.rouletteMain_item > a:after {
  background-color: #ffdb00;
  border-radius: 5px;
  bottom: 0;
  content: '';
  height: 5px;
  left: 0;
  position: absolute;
  transition: width 0.3s;
  width: 0;
}

.rouletteMain_item > a.active:after,
.rouletteMain_item > a:hover:after {
  width: 100%;
}

.rouletteMain_body {
  height: 1000px;
  display: flex;
  justify-content: space-evenly;
}

.rouletteMain_awards,
.rouletteMain_winner {
  flex: 0 0 20.4861111111%;
  position: relative;
  z-index: 1;
}

.rouletteMain_awards {
  align-self: center;
}

.rouletteMain_awards_item {
  padding-bottom: 21.6949152542%;
  position: relative;
  width: 100%;
}

.rouletteMain_awards_item.special > div {
  animation: rouletteSpecialAward 0.6s infinite alternate both;
  border-radius: 30px;
}

.rouletteMain_awards_item.special .rouletteMain_awards_medals {
  background-image: url(https://csi.20icipp.com/img/static/roulette/rouletteMain_awards_medals_0.png);
  height: 100%;
  margin-top: 2.8%;
  width: 18.91%;
}

.rouletteMain_awards_item:first-of-type .rouletteMain_awards_medals {
  background-position: 0 0;
  color: #be5a18;
}

.rouletteMain_awards_item:nth-of-type(2) .rouletteMain_awards_medals {
  background-position: 33.3333% 0;
  color: #6f6f80;
}

.rouletteMain_awards_item:nth-of-type(3) .rouletteMain_awards_medals {
  background-position: 66.6666% 0;
  color: #7c5552;
}

.rouletteMain_awards_item:first-of-type .rouletteMain_awards_medals:before {
  content: '1';
}

.rouletteMain_awards_item:nth-of-type(2) .rouletteMain_awards_medals:before {
  content: '2';
}

.rouletteMain_awards_item:nth-of-type(3) .rouletteMain_awards_medals:before {
  content: '3';
}

.rouletteMain_awards_item:nth-of-type(4) .rouletteMain_awards_medals:before {
  content: '4';
}

.rouletteMain_awards_item:nth-of-type(5) .rouletteMain_awards_medals:before {
  content: '5';
}

.rouletteMain_awards_item:nth-of-type(6) .rouletteMain_awards_medals:before {
  content: '6';
}

.rouletteMain_awards_item:nth-of-type(7) .rouletteMain_awards_medals:before {
  content: '7';
}

.rouletteMain_awards_item:nth-of-type(8) .rouletteMain_awards_medals:before {
  content: '8';
}

.rouletteMain_awards_item:nth-of-type(9) .rouletteMain_awards_medals:before {
  content: '9';
}

.rouletteMain_awards_item > div {
  align-items: center;
  background-image: url(https://s3.ap-southeast-1.amazonaws.com/media-dev.ezgame4u.com/cms/jaya9/image/wof/prize_table.png);
  display: flex;
  justify-content: space-between;
  padding: 0 3.39%;
}

.rouletteMain_awards_item + .rouletteMain_awards_item {
  margin-top: 8.6%;
}

.rouletteMain_awards_medals {
  align-items: center;
  background-image: url(https://csi.20icipp.com/img/static/roulette/rouletteMain_awards_medals.png);
  background-position: 100% 0;
  background-size: 400% 100%;
  color: #674170;
  display: flex;
  font-size: 1.3888888889vw;
  font-weight: 700;
  height: 68.75%;
  justify-content: center;
  width: 16%;
}

.rouletteMain_awards_prize {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 30%;
}

.rouletteMain_awards_prize > img {
  display: block;
  max-height: 90%;
  max-width: 100%;
}

.rouletteMain_awards_text {
  color: #fff;
  font-size: 14px;
  line-height: 1.2;
  text-align: center;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.8);
  width: 60%;
}

.rouletteMain_box {
  flex: 0 0 47.6666666667%;
  padding-bottom: 47.6666666667%;
  position: relative;
}

.rouletteMain_box_wrap {
  padding: 10.5%;
}

.rouletteMain_box_wrap:after,
.rouletteMain_box_wrap:before {
  content: '';
  z-index: 2;
}

.rouletteMain_box_wrap:before {
  background-size: 90%;
  background-position: center;
  background-image: url(https://s3.ap-southeast-1.amazonaws.com/media-dev.ezgame4u.com/cms/babu/image/6225b4f37e777.png);
}

.rouletteMain_box_wrap:after {
  animation: rouletteNeonLight 1s steps(2) infinite;
  background-image: url(https://csi.20icipp.com/img/static/roulette/roulette_neonLight.png);
  background-size: 200% 100%;
}

.rouletteMain_box_part {
  border-radius: 50%;
  height: 100%;
  overflow: hidden;
  position: relative;
  transform: rotate3d(0, 0, 0, 0deg);
  transition: filter 0.4s;
  width: 100%;
}

.rouletteMain_box_part-6 > .rouletteMain_box_part_list {
  left: 25%;
  width: 50%;
}

.rouletteMain_box_part-6 > .rouletteMain_box_part_list:before {
  transform: rotate(-30deg);
}

.rouletteMain_box_part-6 > .rouletteMain_box_part_list.special > .rouletteMain_box_part_color:after {
  background: none;
}

.rouletteMain_box_part-6 > .rouletteMain_box_part_list:first-of-type {
  transform: rotate(0deg);
}

.rouletteMain_box_part-6 > .rouletteMain_box_part_list:nth-of-type(2) {
  transform: rotate(60deg);
}

.rouletteMain_box_part-6 > .rouletteMain_box_part_list:nth-of-type(3) {
  transform: rotate(120deg);
}

.rouletteMain_box_part-6 > .rouletteMain_box_part_list:nth-of-type(4) {
  transform: rotate(180deg);
}

.rouletteMain_box_part-6 > .rouletteMain_box_part_list:nth-of-type(5) {
  transform: rotate(240deg);
}

.rouletteMain_box_part-6 > .rouletteMain_box_part_list:nth-of-type(6) {
  transform: rotate(300deg);
}

.rouletteMain_box_part-6 > .rouletteMain_box_part_list:nth-of-type(odd) > .rouletteMain_box_part_color:before {
  background-image: linear-gradient(-41deg, #94fd01 12%, #1a6642 54%, hsla(0, 0%, 100%, 0) 0);
}

.rouletteMain_box_part-6 > .rouletteMain_box_part_list:nth-of-type(2n) > .rouletteMain_box_part_color:before {
  background-image: linear-gradient(-41deg, #94fd01 12%, #1a6642 54%, hsla(0, 0%, 100%, 0) 0);
}

.rouletteMain_box_part-6 .rouletteMain_box_part_color:before {
  transform: rotate(60deg) skew(30deg);
}

.rouletteMain_box_part-7 > .rouletteMain_box_part_list {
  left: 28.3%;
  width: 43.4%;
}

.rouletteMain_box_part-7 > .rouletteMain_box_part_list:before {
  transform: rotate(-25.7142857143deg);
}

.rouletteMain_box_part-7 > .rouletteMain_box_part_list:nth-of-type(2) {
  transform: rotate(51.4285714286deg);
}

.rouletteMain_box_part-7 > .rouletteMain_box_part_list:nth-of-type(3) {
  transform: rotate(102.8571428571deg);
}

.rouletteMain_box_part-7 > .rouletteMain_box_part_list:nth-of-type(4) {
  transform: rotate(154.2857142857deg);
}

.rouletteMain_box_part-7 > .rouletteMain_box_part_list:nth-of-type(5) {
  transform: rotate(205.7142857143deg);
}

.rouletteMain_box_part-7 > .rouletteMain_box_part_list:nth-of-type(6) {
  transform: rotate(257.1428571429deg);
}

.rouletteMain_box_part-7 > .rouletteMain_box_part_list:nth-of-type(7) {
  transform: rotate(308.5714285714deg);
}

.rouletteMain_box_part-7 > .rouletteMain_box_part_list:nth-of-type(8) {
  transform: rotate(1turn);
}

.rouletteMain_box_part-7 > .rouletteMain_box_part_list:nth-of-type(2) > .rouletteMain_box_part_color:before,
.rouletteMain_box_part-7 > .rouletteMain_box_part_list:nth-of-type(5) > .rouletteMain_box_part_color:before,
.rouletteMain_box_part-7 > .rouletteMain_box_part_list:nth-of-type(8) > .rouletteMain_box_part_color:before {
  background-image: linear-gradient(-38deg, #94fd01 12%, #1a6642 52%, hsla(0, 0%, 100%, 0) 0);
}

.rouletteMain_box_part-7 > .rouletteMain_box_part_list:nth-of-type(2) > .rouletteMain_box_part_color:after,
.rouletteMain_box_part-7 > .rouletteMain_box_part_list:nth-of-type(5) > .rouletteMain_box_part_color:after,
.rouletteMain_box_part-7 > .rouletteMain_box_part_list:nth-of-type(8) > .rouletteMain_box_part_color:after {
  background-image: linear-gradient(38deg, #94fd01 12%, #1a6642 52%, hsla(0, 0%, 100%, 0) 0);
}

.rouletteMain_box_part-7 > .rouletteMain_box_part_list:nth-of-type(3) > .rouletteMain_box_part_color:before,
.rouletteMain_box_part-7 > .rouletteMain_box_part_list:nth-of-type(6) > .rouletteMain_box_part_color:before {
  background-image: linear-gradient(-38deg, #94fd01 12%, #1a6642 52%, hsla(0, 0%, 100%, 0) 0);
}

.rouletteMain_box_part-7 > .rouletteMain_box_part_list:nth-of-type(3) > .rouletteMain_box_part_color:after,
.rouletteMain_box_part-7 > .rouletteMain_box_part_list:nth-of-type(6) > .rouletteMain_box_part_color:after {
  background-image: linear-gradient(38deg, #94fd01 12%, #1a6642 52%, hsla(0, 0%, 100%, 0) 0);
}

.rouletteMain_box_part-7 > .rouletteMain_box_part_list:nth-of-type(4) > .rouletteMain_box_part_color:before,
.rouletteMain_box_part-7 > .rouletteMain_box_part_list:nth-of-type(7) > .rouletteMain_box_part_color:before {
  background-image: linear-gradient(-38deg, #fbc108 12%, #f17007 52%, hsla(0, 0%, 100%, 0) 0);
}

.rouletteMain_box_part-7 > .rouletteMain_box_part_list:nth-of-type(4) > .rouletteMain_box_part_color:after,
.rouletteMain_box_part-7 > .rouletteMain_box_part_list:nth-of-type(7) > .rouletteMain_box_part_color:after {
  background-image: linear-gradient(38deg, #fbc108 12%, #f17007 52%, hsla(0, 0%, 100%, 0) 0);
}

.rouletteMain_box_part-7 .rouletteMain_box_part_color:before {
  transform: rotate(64.4deg) skew(38.8deg);
}

.rouletteMain_box_part-7 .rouletteMain_box_part_color:after {
  transform: rotate(-64.4deg) skew(-38.8deg);
}

.rouletteMain_box_part-8 > .rouletteMain_box_part_list {
  left: 30.9%;
  width: 38.2%;
}

.rouletteMain_box_part-8 > .rouletteMain_box_part_list:before {
  transform: rotate(-22.5deg);
}

.rouletteMain_box_part-8 > .rouletteMain_box_part_list:nth-of-type(2) {
  transform: rotate(45deg);
}

.rouletteMain_box_part-8 > .rouletteMain_box_part_list:nth-of-type(3) {
  transform: rotate(90deg);
}

.rouletteMain_box_part-8 > .rouletteMain_box_part_list:nth-of-type(4) {
  transform: rotate(135deg);
}

.rouletteMain_box_part-8 > .rouletteMain_box_part_list:nth-of-type(5) {
  transform: rotate(180deg);
}

.rouletteMain_box_part-8 > .rouletteMain_box_part_list:nth-of-type(6) {
  transform: rotate(225deg);
}

.rouletteMain_box_part-8 > .rouletteMain_box_part_list:nth-of-type(7) {
  transform: rotate(270deg);
}

.rouletteMain_box_part-8 > .rouletteMain_box_part_list:nth-of-type(8) {
  transform: rotate(315deg);
}

.rouletteMain_box_part-8 > .rouletteMain_box_part_list:nth-of-type(odd) > .rouletteMain_box_part_color:before {
  background-image: linear-gradient(-35.3deg, #94fd01 12%, #1a6642 50%, hsla(0, 0%, 100%, 0) 0);
}

.rouletteMain_box_part-8 > .rouletteMain_box_part_list:nth-of-type(odd) > .rouletteMain_box_part_color:after {
  background-image: linear-gradient(35.3deg, #94fd01 12%, #1a6642 50%, hsla(0, 0%, 100%, 0) 0);
}

.rouletteMain_box_part-8 > .rouletteMain_box_part_list:nth-of-type(2n) > .rouletteMain_box_part_color:before {
  background-image: linear-gradient(-35.3deg, #fbc108 12%, #f17007 50%, hsla(0, 0%, 100%, 0) 0);
}

.rouletteMain_box_part-8 > .rouletteMain_box_part_list:nth-of-type(2n) > .rouletteMain_box_part_color:after {
  background-image: linear-gradient(35.3deg, #fbc108 12%, #f17007 50%, hsla(0, 0%, 100%, 0) 0);
}

.rouletteMain_box_part-8 .rouletteMain_box_part_color:before {
  transform: rotate(67.5deg) skew(45deg);
}

.rouletteMain_box_part-8 .rouletteMain_box_part_color:after {
  transform: rotate(-67.5deg) skew(-45deg);
}

.rouletteMain_box_part-9 > .rouletteMain_box_part_list {
  left: 33%;
  width: 34%;
}

.rouletteMain_box_part-9 > .rouletteMain_box_part_list:before {
  transform: rotate(-20deg);
}

.rouletteMain_box_part-9 > .rouletteMain_box_part_list:nth-of-type(2) {
  transform: rotate(40deg);
}

.rouletteMain_box_part-9 > .rouletteMain_box_part_list:nth-of-type(3) {
  transform: rotate(80deg);
}

.rouletteMain_box_part-9 > .rouletteMain_box_part_list:nth-of-type(4) {
  transform: rotate(120deg);
}

.rouletteMain_box_part-9 > .rouletteMain_box_part_list:nth-of-type(5) {
  transform: rotate(160deg);
}

.rouletteMain_box_part-9 > .rouletteMain_box_part_list:nth-of-type(6) {
  transform: rotate(200deg);
}

.rouletteMain_box_part-9 > .rouletteMain_box_part_list:nth-of-type(7) {
  transform: rotate(240deg);
}

.rouletteMain_box_part-9 > .rouletteMain_box_part_list:nth-of-type(8) {
  transform: rotate(280deg);
}

.rouletteMain_box_part-9 > .rouletteMain_box_part_list:nth-of-type(9) {
  transform: rotate(320deg);
}

.rouletteMain_box_part-9 > .rouletteMain_box_part_list:nth-of-type(2) > .rouletteMain_box_part_color:before,
.rouletteMain_box_part-9 > .rouletteMain_box_part_list:nth-of-type(5) > .rouletteMain_box_part_color:before,
.rouletteMain_box_part-9 > .rouletteMain_box_part_list:nth-of-type(8) > .rouletteMain_box_part_color:before {
  background-image: linear-gradient(-32.7deg, #94fd01 12%, #1a6642 48%, hsla(0, 0%, 100%, 0) 0);
}

.rouletteMain_box_part-9 > .rouletteMain_box_part_list:nth-of-type(2) > .rouletteMain_box_part_color:after,
.rouletteMain_box_part-9 > .rouletteMain_box_part_list:nth-of-type(5) > .rouletteMain_box_part_color:after,
.rouletteMain_box_part-9 > .rouletteMain_box_part_list:nth-of-type(8) > .rouletteMain_box_part_color:after {
  background-image: linear-gradient(32.7deg, #94fd01 12%, #1a6642 48%, hsla(0, 0%, 100%, 0) 0);
}

.rouletteMain_box_part-9 > .rouletteMain_box_part_list:nth-of-type(3) > .rouletteMain_box_part_color:before,
.rouletteMain_box_part-9 > .rouletteMain_box_part_list:nth-of-type(6) > .rouletteMain_box_part_color:before,
.rouletteMain_box_part-9 > .rouletteMain_box_part_list:nth-of-type(9) > .rouletteMain_box_part_color:before {
  background-image: linear-gradient(-32.7deg, #94fd01 12%, #1a6642 48%, hsla(0, 0%, 100%, 0) 0);
}

.rouletteMain_box_part-9 > .rouletteMain_box_part_list:nth-of-type(3) > .rouletteMain_box_part_color:after,
.rouletteMain_box_part-9 > .rouletteMain_box_part_list:nth-of-type(6) > .rouletteMain_box_part_color:after,
.rouletteMain_box_part-9 > .rouletteMain_box_part_list:nth-of-type(9) > .rouletteMain_box_part_color:after {
  background-image: linear-gradient(32.7deg, #94fd01 12%, #1a6642 48%, hsla(0, 0%, 100%, 0) 0);
}

.rouletteMain_box_part-9 > .rouletteMain_box_part_list:nth-of-type(4) > .rouletteMain_box_part_color:before,
.rouletteMain_box_part-9 > .rouletteMain_box_part_list:nth-of-type(7) > .rouletteMain_box_part_color:before {
  background-image: linear-gradient(-32.7deg, #fbc108 12%, #f17007 48%, hsla(0, 0%, 100%, 0) 0);
}

.rouletteMain_box_part-9 > .rouletteMain_box_part_list:nth-of-type(4) > .rouletteMain_box_part_color:after,
.rouletteMain_box_part-9 > .rouletteMain_box_part_list:nth-of-type(7) > .rouletteMain_box_part_color:after {
  background-image: linear-gradient(32.7deg, #fbc108 12%, #f17007 48%, hsla(0, 0%, 100%, 0) 0);
}

.rouletteMain_box_part-9 .rouletteMain_box_part_color:before {
  transform: rotate(70deg) skew(50deg);
}

.rouletteMain_box_part-9 .rouletteMain_box_part_color:after {
  transform: rotate(-70deg) skew(-50deg);
}

.rouletteMain_box_part_list {
  align-items: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  height: 50%;
  justify-content: center;
  padding-bottom: 2%;
  position: absolute;
  top: 0;
  transform-origin: 50% 100%;
}

.rouletteMain_box_part_list:before {
  animation-direction: alternate;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  background-image: linear-gradient(0deg, #ffcf00, #ff0 14%, #ff0 35%, #fff 42%, #ffc600 72%);
  content: '';
  height: 100%;
  left: 50%;
  margin-left: -1px;
  position: absolute;
  top: 0;
  transform-origin: 50% 100%;
  transition: width 0.3s, margin-left 0.3s;
  width: 2px;
  z-index: 1;
}

.rouletteMain_box_part_list:after {
  font-family: Verdana, sans-serif;
  font-size: 4.5833333333vw;
  font-weight: 700;
  position: relative;
  text-shadow: -0.2083333333vw -0.2777777778vw 0 #dd3f07, -0.1388888889vw -0.2777777778vw 0 #dd3f07, -0.0694444444vw -0.2777777778vw 0 #dd3f07, 0 -0.2777777778vw 0 #dd3f07,
    0.0694444444vw -0.2777777778vw 0 #dd3f07, 0.1388888889vw -0.2777777778vw 0 #dd3f07, 0.2083333333vw -0.2777777778vw 0 #dd3f07, 0.2777777778vw -0.2777777778vw 0 #dd3f07,
    0.2777777778vw -0.2083333333vw 0 #dd3f07, 0.2777777778vw -0.1388888889vw 0 #dd3f07, 0.2777777778vw -0.0694444444vw 0 #dd3f07, 0.2777777778vw 0 0 #dd3f07, 0.2777777778vw 0.0694444444vw 0 #dd3f07,
    0.2777777778vw 0.1388888889vw 0 #dd3f07, 0.2777777778vw 0.2083333333vw 0 #dd3f07, 0.2777777778vw 0.2777777778vw 0 #dd3f07, 0.2083333333vw 0.2777777778vw 0 #dd3f07,
    0.1388888889vw 0.2777777778vw 0 #dd3f07, 0.0694444444vw 0.2777777778vw 0 #dd3f07, 0 0.2777777778vw 0 #dd3f07, -0.0694444444vw 0.2777777778vw 0 #dd3f07, -0.1388888889vw 0.2777777778vw 0 #dd3f07,
    -0.2083333333vw 0.2777777778vw 0 #dd3f07, -0.2777777778vw 0.2777777778vw 0 #dd3f07, -0.2777777778vw 0.2083333333vw 0 #dd3f07, -0.2777777778vw 0.1388888889vw 0 #dd3f07,
    -0.2777777778vw 0.0694444444vw 0 #dd3f07, -0.2777777778vw 0 0 #dd3f07, -0.2777777778vw -0.0694444444vw 0 #dd3f07, -0.2777777778vw -0.1388888889vw 0 #dd3f07,
    -0.2777777778vw -0.2083333333vw 0 #dd3f07, -0.2777777778vw -0.2777777778vw 0 #dd3f07;
}

.rouletteMain_box_part_list.special:after {
  -webkit-text-stroke: 3px #dd3f07;
  content: 'î§™';
  font-family: rouletteStar;
  text-shadow: none;
}

.rouletteMain_box_part_list.special > .rouletteMain_box_part_color:after,
.rouletteMain_box_part_list.special > .rouletteMain_box_part_color:before {
  background-color: #ff0056;
}

/*.rouletteMain_box_part_list:first-of-type:after {*/
/*  content: ""*/
/*}*/

/*.rouletteMain_box_part_list:nth-of-type(2):after {*/
/*  content: ""*/
/*}*/

/*.rouletteMain_box_part_list:nth-of-type(3):after {*/
/*  content: ""*/
/*}*/

/*.rouletteMain_box_part_list:nth-of-type(4):after {*/
/*  content: ""*/
/*}*/

/*.rouletteMain_box_part_list:nth-of-type(5):after {*/
/*  content: ""*/
/*}*/

/*.rouletteMain_box_part_list:nth-of-type(6):after {*/
/*  content: ""*/
/*}*/

/*.rouletteMain_box_part_list:nth-of-type(7):after {*/
/*  content: ""*/
/*}*/

/*.rouletteMain_box_part_list:nth-of-type(8):after {*/
/*  content: ""*/
/*}*/

/*.rouletteMain_box_part_list:nth-of-type(9):after {*/
/*  content: ""*/
/*}*/

.rouletteMain_box_part_color {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.rouletteMain_box_part_color:after,
.rouletteMain_box_part_color:before {
  content: '';
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}

.rouletteMain_box_part_color:before {
  left: -50%;
  transform-origin: 100% 100%;
}

.rouletteMain_box_part_color:after {
  left: 50%;
  transform-origin: 0 100%;
}

.rouletteMain_box_part_award {
  line-height: 1.2;
  position: relative;
  text-align: center;
  width: 70%;
}

.rouletteMain_box_coin {
  height: 125%;
  left: -25%;
  pointer-events: none;
  position: absolute;
  top: -12.5%;
  width: 150%;
  z-index: 3;
}

.rouletteMain_box_coinWrap {
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  animation-name: rouletteBoxCoin;
  animation-timing-function: linear;
}

.rouletteMain_box_coinWrap:first-of-type {
  animation-delay: -1s;
  animation-duration: 5s;
}

.rouletteMain_box_coinWrap:nth-of-type(2) {
  animation-delay: -2s;
  animation-duration: 7s;
}

.rouletteMain_box_coinWrap:nth-of-type(3) {
  animation-delay: -1.5s;
  animation-duration: 6s;
}

.rouletteMain_box_coinWrap:nth-of-type(4) {
  animation-delay: -0.5s;
  animation-duration: 4s;
}

.rouletteMain_box_coinWrap:nth-of-type(5) {
  animation-delay: -2.5s;
  animation-duration: 8s;
}

.rouletteMain_box_coinWrap:nth-of-type(6) {
  animation-delay: -3s;
  animation-duration: 9s;
}

.rouletteMain_box_coin_1,
.rouletteMain_box_coin_2,
.rouletteMain_box_coin_3,
.rouletteMain_box_coin_4,
.rouletteMain_box_coin_5,
.rouletteMain_box_coin_6 {
  position: absolute;
}

.rouletteMain_box_coin_1 {
  left: 76%;
  top: 47%;
  width: 12.7777777778%;
}

.rouletteMain_box_coin_2 {
  left: 15%;
  top: 26%;
  width: 9.2222222222%;
}

.rouletteMain_box_coin_3 {
  left: 68%;
  top: 71%;
  width: 15.4444444444%;
}

.rouletteMain_box_coin_4 {
  left: 11%;
  top: 71%;
  width: 30.4444444444%;
}

.rouletteMain_box_coin_5 {
  left: 73%;
  top: 17%;
  width: 17.1111111111%;
}

.rouletteMain_box_coin_6 {
  left: 24%;
  top: 4%;
  width: 7.3333333333%;
}

.rouletteMain_box_btn,
.rouletteMain_box_numberTimes {
  background-size: 100% 100%;
  position: absolute;
  z-index: 3;
}

.rouletteMain_box_btn {
  animation: rouletteMainBoxBtnShadow 0.5s infinite alternate both;
  background-position: -10px -12px;
  background-size: 230% 115%;
  /*background-image: url(https://csi.20icipp.com/img/static/roulette/roulette_btn.png);*/
  border-radius: 50%;
  bottom: 0;
  box-shadow: 0 0 46px rgba(0, 0, 0, 0.75), 0 2px 3px 1px rgba(0, 0, 0, 0.35), inset -1px 0 1px rgba(0, 0, 0, 0.5), inset 1px 1px 1px #fff;
  cursor: pointer;
  display: flex;
  height: 18.3333333333%;
  left: 0;
  margin: auto;
  overflow: hidden;
  padding-bottom: 1.5%;
  right: 0;
  top: 0;
  width: 18.3333333333%;
}

.rouletteMain_box_btn:after {
  animation: rouletteMainBoxBtnLight 2s infinite both;
  background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0.7) 60%, hsla(0, 0%, 100%, 0.4) 0, hsla(0, 0%, 100%, 0));
  content: '';
  height: 40%;
  left: -40%;
  position: absolute;
  top: 30%;
  transform: translate3d(80%, 0, 0) rotate(-45deg);
  width: 180%;
}

.rouletteMain_box_logo {
  margin: auto;
  max-width: 78%;
  pointer-events: none;
}

.rouletteMain_box_numberTimes {
  animation: rouletteNumberTimes 0.6s 0.6s infinite alternate both;
  background-image: url(https://jiliwin.9terawolf.com/images/wof/Coin_Frame.png);
  border-radius: 9%/18.75%;
  box-shadow: 0 0 16px 2px rgba(252, 255, 0, 0.5), inset 0 0 1px rgba(252, 255, 0, 0.5);
  height: 0;
  padding-bottom: 12%;
  right: 0;
  top: 0;
  width: 25%;
}

.rouletteMain_box_numberTimes .point_rate {
  align-items: center;
  background-color: rgba(95, 47, 245, 0.9);
  border-radius: 100px;
  box-shadow: inset 0 0 0 1px #d3c5fa, inset 0 0 10px #b670f7, 0 2px 6px rgba(0, 0, 0, 0.8);
  color: #fff;
  display: flex;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  height: 25px;
  left: auto;
  padding: 0 15px;
  position: absolute;
  right: -10px;
  text-shadow: 0 0 0 #fff;
  text-shadow: 0 1px 1px #27095c;
  transform: skew(0deg) translateY(-8px);
  width: auto;
}

.rouletteMain_box_numberTimes .point_rate b {
  color: #ffefbd;
  font-size: 16px;
  font-weight: 700;
  padding-right: 3px;
}

.rouletteMain_box_numberTimes > div {
  align-content: center;
  align-items: center;
  color: #ff4100;
  display: flex;
  flex-wrap: wrap;
  font-weight: 700;
  justify-content: center;
  padding-bottom: 1%;
  text-align: center;
  text-shadow: 2px 2px 0 #f3cb00;
  transform: skew(-6deg);
}

.rouletteMain_box_numberTimes > div > div {
  width: 100%;
}

.rouletteMain_box_numberTimes span:first-of-type {
  font-size: 28px;
}

.rouletteMain_box_numberTimes span:nth-of-type(2) {
  font-size: 13px;
  letter-spacing: -1px;
  margin: 6% 0 0 1%;
}

.rouletteMain_winner {
  background-image: url(https://s3.ap-southeast-1.amazonaws.com/media-dev.ezgame4u.com/cms/jaya9/image/wof/longpanel.png);
  background-size: 100% 100%;
  padding-bottom: 41.6666666667%;
}

.rouletteMain_winner_wrap {
  display: flex;
  flex-direction: column;
  padding: 5.0847457627%;
}

.rouletteMain_winner_wrap:after,
.rouletteMain_winner_wrap:before {
  left: 50%;
  z-index: 1;
}

.rouletteMain_winner_wrap:before {
  background-image: url(https://s3.ap-southeast-1.amazonaws.com/media-dev.ezgame4u.com/cms/jaya9/image/wof/trophy_krikya.png);
  padding-bottom: 46.7796610169%;
  top: 0;
  transform: translate(-50%, -96%);
  width: 111.186440678%;
}

.rouletteMain_winner_wrap:after {
  background-image: url(https://csi.20icipp.com/img/static/roulette/rouletteMain_winnerList_b.png);
  bottom: 0;
  padding-bottom: 28.813559322%;
  transform: translate(-50%, 40%);
  width: 124.0677966102%;
}

.rouletteMain_winner_head {
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 20px;
  display: flex;
  margin-bottom: 5.6603773585%;
  position: relative;
}

.rouletteMain_winner_head_item {
  color: #fbc108;
  cursor: pointer;
  flex: 1 1 0;
  font-size: 1.25vw;
  line-height: 2.3611111111vw;
  position: relative;
  text-align: center;
  transition: color 0.4s, text-shadow 0.4s;
  z-index: 1;
}

.rouletteMain_winner_head_cursor {
  background-image: url(https://s3.ap-southeast-1.amazonaws.com/cdn.v3c33.com/v3/wheel/rouletteMain_winner_btn.png);
  background-size: 100% 100%;
  height: 0;
  margin: 0 0 0 -12px;
  padding-bottom: 14.3396226415%;
  position: absolute;
  top: 0;
  transition: left 0.4s;
  width: 60.830189%;
}

.rouletteMain_winner_head.winnerList > div:first-of-type {
  color: #fff;
  text-shadow: 0 1px 1px #5a29a7;
}

.rouletteMain_winner_head.winnerList + .rouletteMain_winner_slider > ul,
.rouletteMain_winner_head.winnerList > .rouletteMain_winner_head_cursor {
  left: 0;
}

.rouletteMain_winner_head.winnerRecord > div:nth-of-type(2) {
  color: #fff;
  text-shadow: 0 1px 1px #5a29a7;
}

.rouletteMain_winner_head.winnerRecord > .rouletteMain_winner_head_cursor {
  left: 50%;
}

.rouletteMain_winner_head.winnerRecord + .rouletteMain_winner_slider > ul:first-of-type {
  left: 100%;
}

.rouletteMain_winner_head.winnerRecord + .rouletteMain_winner_slider > ul:nth-of-type(2) {
  left: -100%;
}

.rouletteMain_winner_slider {
  display: flex;
  flex: 1 1 0;
  overflow: hidden;
}

.rouletteMain_winner_list {
  flex: 0 0 100%;
  overflow-y: auto;
  position: relative;
  transition: left 0.4s;
}

.rouletteMain_winner_item {
  color: #fff;
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  line-height: 38px;
  padding: 0 3.5%;
}

.rouletteMain_winner_date {
  font-size: 11px;
  padding-right: 8px;
  white-space: nowrap;
}

.rouletteMain_winner_prize {
  color: #f56908;
  text-align: center;
  width: 46%;
}

.rouletteRule {
  background-color: black;
  padding: 45px 12px 85px;
}

.rouletteRule_wrap {
  background-color: #1a6642;
  border-radius: 20px;
  box-shadow: inset 0 4px 7px rgba(255, 255, 255, 0.35), inset 0 -4px 7px rgba(0, 0, 0, 0.25), 0 0 45px rgba(0, 0, 0, 0.7);
  color: #fff;
  margin: 0 auto;
  max-width: 1000px;
  min-height: 350px;
  padding: 80px 45px;
  position: relative;
}

.rouletteRule_title {
  background-image: url(https://s3.ap-southeast-1.amazonaws.com/cdn.v3c33.com/v3/wheel/roulette_rule_title.png);
  font-size: 24px;
  height: 75px;
  left: 50%;
  line-height: 76px;
  position: absolute;
  text-align: center;
  text-shadow: 0 -3px 0 rgba(0, 0, 0, 0.5);
  top: 0;
  transform: translate3d(-50%, -50%, 0);
  width: 366px;
}

.rouletteRule_text {
  font-size: 16px;
  line-height: 24px;
}

.rouletteRule_text + .rouletteRule_text {
  margin-top: 24px;
}

.roulettePopup {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -2;
}

.roulettePopup_wrap {
  display: flex;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 1;
}

.roulettePopup_wrap:before {
  background-color: transparent;
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition: background-color 0.2s, backdrop-filter 0.2s;
  width: 100%;
  z-index: -1;
}

.roulettePopup_box {
  align-items: center;
  background-image: url(https://csi.20icipp.com/img/static/roulette/roulettePopup_box.png);
  background-size: cover;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 72vh;
  justify-content: center;
  margin: auto;
  max-height: 630px;
  max-width: 776px;
  min-height: 430px;
  opacity: 0;
  position: relative;
  transform: scale3d(0.5, 0.5, 1);
  transition: opacity 0.3s 0.1s, transform 0.3s 0.1s;
  width: 88.6857142857vh;
}

.roulettePopup_box:after,
.roulettePopup_box:before {
  background-size: cover;
  content: '';
  height: 80.9523809524%;
  left: 2%;
  pointer-events: none;
  position: absolute;
  top: 10%;
  width: 103.0927835052%;
}

.roulettePopup_box:before {
  animation-direction: alternate;
  animation-timing-function: linear;
  background-image: url(https://csi.20icipp.com/img/static/roulette/roulettePopup_blue.png);
  transform-origin: 40% 50%;
}

.roulettePopup_box:after {
  animation-direction: alternate-reverse;
  background-image: url(https://csi.20icipp.com/img/static/roulette/roulettePopup_red.png);
  transform-origin: 55% 40%;
}

.roulettePopup_box > p {
  font-size: 1.4583333333vw;
  padding: 1.1111111111vw 0;
}

.roulettePopup_box > h3 {
  font-size: 2.6388888889vw;
  font-weight: 700;
  padding-bottom: 1.1111111111vw;
  position: relative;
  text-shadow: 0 0 1.25vw #6404f3, 0 0 2.5vw #6404f3, 0 1px 2.5vw #6404f3;
  z-index: 1;
}

.roulettePopup_box > img {
  margin-bottom: 5px;
  position: relative;
  width: 15.9722222222vw;
  z-index: 1;
}

.roulettePopup_tenTimes {
  align-content: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 12.8708333333vw;
  justify-content: center;
  margin-bottom: 10px;
  position: relative;
  width: 80%;
  z-index: 1;
}

.roulettePopup_tenTimes > h3 {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 100px;
  display: flex;
  font-size: 16px;
  height: 17%;
  line-height: 1;
  margin: 0.5% 2px 0;
  padding: 0 5px;
  text-shadow: 0 0 0.6944444444vw #6404f3, 0 0 1.5277777778vw #6404f3, 0 1px 1.5277777778vw #6404f3;
  width: 50%;
}

.roulettePopup_tenTimes > h3 b {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.roulettePopup_tenTimes > h3:before {
  background-color: #6404f3;
  border-radius: 50%;
  box-shadow: 0.5px 0 0 2px #6404f3;
  display: inline-block;
  flex: 1.3368055556vw 0 0;
  font-size: 1.1111111111vw;
  font-weight: 400;
  height: 1.3368055556vw;
  line-height: 1.3368055556vw;
  margin-right: 0.4166666667vw;
  text-align: center;
  vertical-align: top;
  width: 1.3888888889vw;
}

.roulettePopup_tenTimes > h3:first-of-type:before {
  content: '1';
}

.roulettePopup_tenTimes > h3:nth-of-type(2):before {
  content: '2';
}

.roulettePopup_tenTimes > h3:nth-of-type(3):before {
  content: '3';
}

.roulettePopup_tenTimes > h3:nth-of-type(4):before {
  content: '4';
}

.roulettePopup_tenTimes > h3:nth-of-type(5):before {
  content: '5';
}

.roulettePopup_tenTimes > h3:nth-of-type(6):before {
  content: '6';
}

.roulettePopup_tenTimes > h3:nth-of-type(7):before {
  content: '7';
}

.roulettePopup_tenTimes > h3:nth-of-type(8):before {
  content: '8';
}

.roulettePopup_tenTimes > h3:nth-of-type(9):before {
  content: '9';
}

.roulettePopup_tenTimes > h3:nth-of-type(10):before {
  content: '10';
}

.roulettePopup_btnWrap {
  display: flex;
  margin-bottom: 15px;
  position: relative;
  z-index: 1;
}

.roulettePopup_btnWrap > .roulettePopup_btn {
  padding: 0 19px;
}

.roulettePopup_btnWrap > .roulettePopup_btn + .roulettePopup_btn {
  margin-left: 20px;
}

.roulettePopup .roulettePopup_btn {
  cursor: pointer;
}

.roulettePopup_btn {
  background-color: rgba(0, 0, 0, 0.75);
  background-image: url(https://csi.20icipp.com/img/static/roulette/roulette_winner_btn_lm.png), url(https://csi.20icipp.com/img/static/roulette/roulette_winner_btn_cm.png),
    url(https://csi.20icipp.com/img/static/roulette/roulette_winner_btn_rm.png);
  background-position: 0 0, 50% 0, 100% 0;
  background-repeat: no-repeat;
  background-size: 19px 100%, calc(100% - 36px) 100%, 19px 100%;
  border-radius: 20px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.75), inset 0 0 2px 2px rgba(0, 0, 0, 0.75);
  font-size: 16px;
  line-height: 38px;
  padding: 0 36px;
  text-align: center;
  text-shadow: 0 1px 1px #5a29a7;
}

.roulettePopup_btn.disabled {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}

.roulettePopup_close {
  height: 50px;
  padding: 6px;
  position: absolute;
  right: 7.5%;
  top: 7.5%;
  width: 50px;
}

.roulettePopup_close > div {
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 50%;
  cursor: pointer;
  height: 100%;
}

.roulettePopup_close > div:after,
.roulettePopup_close > div:before {
  background-color: #000;
  border-radius: 2px;
  content: '';
  height: 2px;
  left: 50%;
  position: absolute;
  top: 50%;
  width: 22px;
}

.roulettePopup_close > div:before {
  transform: translate3d(-50%, -50%, 0) rotate(45deg);
}

.roulettePopup_close > div:after {
  transform: translate3d(-50%, -50%, 0) rotate(-45deg);
}

.rouletteMain_box_btn.disabled {
  background-position: 100% 0;
  pointer-events: none;
}

.rouletteMain_box_btn.disabled:after {
  animation-name: none;
}

.rouletteMain_box_btn.disabled .rouletteMain_box_logo {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}

.rouletteMain_box_wrap-rotate > .rouletteMain_box_part {
  animation-iteration-count: infinite;
  animation-name: rouletteMainBoxPart;
  animation-timing-function: linear;
}

.rouletteMain_box_wrap-rotate > .rouletteMain_box_part-9 {
  animation-duration: 0.3s;
}

.rouletteMain_box_wrap-rotate > .rouletteMain_box_part-8 {
  animation-duration: 0.275s;
}

.rouletteMain_box_wrap-rotate > .rouletteMain_box_part-7 {
  animation-duration: 0.25s;
}

.rouletteMain_box_wrap-rotate > .rouletteMain_box_part-6 {
  animation-duration: 0.225s;
}

.rouletteMain_box_wrap-rotate .rouletteMain_box_btn {
  background-position: 100% 0;
  pointer-events: none;
}

.rouletteMain_box_wrap-rotate .rouletteMain_box_btn:after {
  animation-name: none;
}

.rouletteMain_box_wrap-rotate .rouletteMain_box_logo {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}

.rouletteMain_P6_N0 .rouletteMain_box_part_list:first-of-type:before,
.rouletteMain_P6_N0 .rouletteMain_box_part_list:nth-of-type(2):before,
.rouletteMain_P6_N1 .rouletteMain_box_part_list:nth-of-type(2):before,
.rouletteMain_P6_N1 .rouletteMain_box_part_list:nth-of-type(3):before,
.rouletteMain_P6_N2 .rouletteMain_box_part_list:nth-of-type(3):before,
.rouletteMain_P6_N2 .rouletteMain_box_part_list:nth-of-type(4):before,
.rouletteMain_P6_N3 .rouletteMain_box_part_list:nth-of-type(4):before,
.rouletteMain_P6_N3 .rouletteMain_box_part_list:nth-of-type(5):before,
.rouletteMain_P6_N4 .rouletteMain_box_part_list:nth-of-type(5):before,
.rouletteMain_P6_N4 .rouletteMain_box_part_list:nth-of-type(6):before,
.rouletteMain_P6_N5 .rouletteMain_box_part_list:first-of-type:before,
.rouletteMain_P6_N5 .rouletteMain_box_part_list:nth-of-type(6):before,
.rouletteMain_P7_N0 .rouletteMain_box_part_list:first-of-type:before,
.rouletteMain_P7_N0 .rouletteMain_box_part_list:nth-of-type(2):before,
.rouletteMain_P7_N1 .rouletteMain_box_part_list:nth-of-type(2):before,
.rouletteMain_P7_N1 .rouletteMain_box_part_list:nth-of-type(3):before,
.rouletteMain_P7_N2 .rouletteMain_box_part_list:nth-of-type(3):before,
.rouletteMain_P7_N2 .rouletteMain_box_part_list:nth-of-type(4):before,
.rouletteMain_P7_N3 .rouletteMain_box_part_list:nth-of-type(4):before,
.rouletteMain_P7_N3 .rouletteMain_box_part_list:nth-of-type(5):before,
.rouletteMain_P7_N4 .rouletteMain_box_part_list:nth-of-type(5):before,
.rouletteMain_P7_N4 .rouletteMain_box_part_list:nth-of-type(6):before,
.rouletteMain_P7_N5 .rouletteMain_box_part_list:nth-of-type(6):before,
.rouletteMain_P7_N5 .rouletteMain_box_part_list:nth-of-type(7):before,
.rouletteMain_P7_N6 .rouletteMain_box_part_list:first-of-type:before,
.rouletteMain_P7_N6 .rouletteMain_box_part_list:nth-of-type(7):before,
.rouletteMain_P8_N0 .rouletteMain_box_part_list:first-of-type:before,
.rouletteMain_P8_N0 .rouletteMain_box_part_list:nth-of-type(2):before,
.rouletteMain_P8_N1 .rouletteMain_box_part_list:nth-of-type(2):before,
.rouletteMain_P8_N1 .rouletteMain_box_part_list:nth-of-type(3):before,
.rouletteMain_P8_N2 .rouletteMain_box_part_list:nth-of-type(3):before,
.rouletteMain_P8_N2 .rouletteMain_box_part_list:nth-of-type(4):before,
.rouletteMain_P8_N3 .rouletteMain_box_part_list:nth-of-type(4):before,
.rouletteMain_P8_N3 .rouletteMain_box_part_list:nth-of-type(5):before,
.rouletteMain_P8_N4 .rouletteMain_box_part_list:nth-of-type(5):before,
.rouletteMain_P8_N4 .rouletteMain_box_part_list:nth-of-type(6):before,
.rouletteMain_P8_N5 .rouletteMain_box_part_list:nth-of-type(6):before,
.rouletteMain_P8_N5 .rouletteMain_box_part_list:nth-of-type(7):before,
.rouletteMain_P8_N6 .rouletteMain_box_part_list:nth-of-type(7):before,
.rouletteMain_P8_N6 .rouletteMain_box_part_list:nth-of-type(8):before,
.rouletteMain_P8_N7 .rouletteMain_box_part_list:first-of-type:before,
.rouletteMain_P8_N7 .rouletteMain_box_part_list:nth-of-type(8):before,
.rouletteMain_P9_N0 .rouletteMain_box_part_list:first-of-type:before,
.rouletteMain_P9_N0 .rouletteMain_box_part_list:nth-of-type(2):before,
.rouletteMain_P9_N1 .rouletteMain_box_part_list:nth-of-type(2):before,
.rouletteMain_P9_N1 .rouletteMain_box_part_list:nth-of-type(3):before,
.rouletteMain_P9_N2 .rouletteMain_box_part_list:nth-of-type(3):before,
.rouletteMain_P9_N2 .rouletteMain_box_part_list:nth-of-type(4):before,
.rouletteMain_P9_N3 .rouletteMain_box_part_list:nth-of-type(4):before,
.rouletteMain_P9_N3 .rouletteMain_box_part_list:nth-of-type(5):before,
.rouletteMain_P9_N4 .rouletteMain_box_part_list:nth-of-type(5):before,
.rouletteMain_P9_N4 .rouletteMain_box_part_list:nth-of-type(6):before,
.rouletteMain_P9_N5 .rouletteMain_box_part_list:nth-of-type(6):before,
.rouletteMain_P9_N5 .rouletteMain_box_part_list:nth-of-type(7):before,
.rouletteMain_P9_N6 .rouletteMain_box_part_list:nth-of-type(7):before,
.rouletteMain_P9_N6 .rouletteMain_box_part_list:nth-of-type(8):before,
.rouletteMain_P9_N7 .rouletteMain_box_part_list:nth-of-type(8):before,
.rouletteMain_P9_N7 .rouletteMain_box_part_list:nth-of-type(9):before,
.rouletteMain_P9_N8 .rouletteMain_box_part_list:first-of-type:before,
.rouletteMain_P9_N8 .rouletteMain_box_part_list:nth-of-type(9):before {
  animation-delay: 2.2s;
  animation-name: roulettePrizeNumber;
  margin-left: -2px;
  transition-delay: 2.2s;
  width: 4px;
}

.rouletteMain_P6_N0 > .rouletteMain_box_part,
.rouletteMain_P6_N1 > .rouletteMain_box_part,
.rouletteMain_P6_N2 > .rouletteMain_box_part,
.rouletteMain_P6_N3 > .rouletteMain_box_part,
.rouletteMain_P6_N4 > .rouletteMain_box_part,
.rouletteMain_P6_N5 > .rouletteMain_box_part,
.rouletteMain_P6_N6 > .rouletteMain_box_part,
.rouletteMain_P6_N7 > .rouletteMain_box_part,
.rouletteMain_P6_N8 > .rouletteMain_box_part,
.rouletteMain_P7_N0 > .rouletteMain_box_part,
.rouletteMain_P7_N1 > .rouletteMain_box_part,
.rouletteMain_P7_N2 > .rouletteMain_box_part,
.rouletteMain_P7_N3 > .rouletteMain_box_part,
.rouletteMain_P7_N4 > .rouletteMain_box_part,
.rouletteMain_P7_N5 > .rouletteMain_box_part,
.rouletteMain_P7_N6 > .rouletteMain_box_part,
.rouletteMain_P7_N7 > .rouletteMain_box_part,
.rouletteMain_P7_N8 > .rouletteMain_box_part,
.rouletteMain_P8_N0 > .rouletteMain_box_part,
.rouletteMain_P8_N1 > .rouletteMain_box_part,
.rouletteMain_P8_N2 > .rouletteMain_box_part,
.rouletteMain_P8_N3 > .rouletteMain_box_part,
.rouletteMain_P8_N4 > .rouletteMain_box_part,
.rouletteMain_P8_N5 > .rouletteMain_box_part,
.rouletteMain_P8_N6 > .rouletteMain_box_part,
.rouletteMain_P8_N7 > .rouletteMain_box_part,
.rouletteMain_P8_N8 > .rouletteMain_box_part,
.rouletteMain_P9_N0 > .rouletteMain_box_part,
.rouletteMain_P9_N1 > .rouletteMain_box_part,
.rouletteMain_P9_N2 > .rouletteMain_box_part,
.rouletteMain_P9_N3 > .rouletteMain_box_part,
.rouletteMain_P9_N4 > .rouletteMain_box_part,
.rouletteMain_P9_N5 > .rouletteMain_box_part,
.rouletteMain_P9_N6 > .rouletteMain_box_part,
.rouletteMain_P9_N7 > .rouletteMain_box_part,
.rouletteMain_P9_N8 > .rouletteMain_box_part {
  animation-duration: 2.2s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0, 0, 0.15, 1.1);
}

.rouletteMain_P6_N0 .rouletteMain_box_part_award,
.rouletteMain_P6_N0 .rouletteMain_box_part_list:after,
.rouletteMain_P6_N1 .rouletteMain_box_part_award,
.rouletteMain_P6_N1 .rouletteMain_box_part_list:after,
.rouletteMain_P6_N2 .rouletteMain_box_part_award,
.rouletteMain_P6_N2 .rouletteMain_box_part_list:after,
.rouletteMain_P6_N3 .rouletteMain_box_part_award,
.rouletteMain_P6_N3 .rouletteMain_box_part_list:after,
.rouletteMain_P6_N4 .rouletteMain_box_part_award,
.rouletteMain_P6_N4 .rouletteMain_box_part_list:after,
.rouletteMain_P6_N5 .rouletteMain_box_part_award,
.rouletteMain_P6_N5 .rouletteMain_box_part_list:after,
.rouletteMain_P6_N6 .rouletteMain_box_part_award,
.rouletteMain_P6_N6 .rouletteMain_box_part_list:after,
.rouletteMain_P6_N7 .rouletteMain_box_part_award,
.rouletteMain_P6_N7 .rouletteMain_box_part_list:after,
.rouletteMain_P6_N8 .rouletteMain_box_part_award,
.rouletteMain_P6_N8 .rouletteMain_box_part_list:after,
.rouletteMain_P7_N0 .rouletteMain_box_part_award,
.rouletteMain_P7_N0 .rouletteMain_box_part_list:after,
.rouletteMain_P7_N1 .rouletteMain_box_part_award,
.rouletteMain_P7_N1 .rouletteMain_box_part_list:after,
.rouletteMain_P7_N2 .rouletteMain_box_part_award,
.rouletteMain_P7_N2 .rouletteMain_box_part_list:after,
.rouletteMain_P7_N3 .rouletteMain_box_part_award,
.rouletteMain_P7_N3 .rouletteMain_box_part_list:after,
.rouletteMain_P7_N4 .rouletteMain_box_part_award,
.rouletteMain_P7_N4 .rouletteMain_box_part_list:after,
.rouletteMain_P7_N5 .rouletteMain_box_part_award,
.rouletteMain_P7_N5 .rouletteMain_box_part_list:after,
.rouletteMain_P7_N6 .rouletteMain_box_part_award,
.rouletteMain_P7_N6 .rouletteMain_box_part_list:after,
.rouletteMain_P7_N7 .rouletteMain_box_part_award,
.rouletteMain_P7_N7 .rouletteMain_box_part_list:after,
.rouletteMain_P7_N8 .rouletteMain_box_part_award,
.rouletteMain_P7_N8 .rouletteMain_box_part_list:after,
.rouletteMain_P8_N0 .rouletteMain_box_part_award,
.rouletteMain_P8_N0 .rouletteMain_box_part_list:after,
.rouletteMain_P8_N1 .rouletteMain_box_part_award,
.rouletteMain_P8_N1 .rouletteMain_box_part_list:after,
.rouletteMain_P8_N2 .rouletteMain_box_part_award,
.rouletteMain_P8_N2 .rouletteMain_box_part_list:after,
.rouletteMain_P8_N3 .rouletteMain_box_part_award,
.rouletteMain_P8_N3 .rouletteMain_box_part_list:after,
.rouletteMain_P8_N4 .rouletteMain_box_part_award,
.rouletteMain_P8_N4 .rouletteMain_box_part_list:after,
.rouletteMain_P8_N5 .rouletteMain_box_part_award,
.rouletteMain_P8_N5 .rouletteMain_box_part_list:after,
.rouletteMain_P8_N6 .rouletteMain_box_part_award,
.rouletteMain_P8_N6 .rouletteMain_box_part_list:after,
.rouletteMain_P8_N7 .rouletteMain_box_part_award,
.rouletteMain_P8_N7 .rouletteMain_box_part_list:after,
.rouletteMain_P8_N8 .rouletteMain_box_part_award,
.rouletteMain_P8_N8 .rouletteMain_box_part_list:after,
.rouletteMain_P9_N0 .rouletteMain_box_part_award,
.rouletteMain_P9_N0 .rouletteMain_box_part_list:after,
.rouletteMain_P9_N1 .rouletteMain_box_part_award,
.rouletteMain_P9_N1 .rouletteMain_box_part_list:after,
.rouletteMain_P9_N2 .rouletteMain_box_part_award,
.rouletteMain_P9_N2 .rouletteMain_box_part_list:after,
.rouletteMain_P9_N3 .rouletteMain_box_part_award,
.rouletteMain_P9_N3 .rouletteMain_box_part_list:after,
.rouletteMain_P9_N4 .rouletteMain_box_part_award,
.rouletteMain_P9_N4 .rouletteMain_box_part_list:after,
.rouletteMain_P9_N5 .rouletteMain_box_part_award,
.rouletteMain_P9_N5 .rouletteMain_box_part_list:after,
.rouletteMain_P9_N6 .rouletteMain_box_part_award,
.rouletteMain_P9_N6 .rouletteMain_box_part_list:after,
.rouletteMain_P9_N7 .rouletteMain_box_part_award,
.rouletteMain_P9_N7 .rouletteMain_box_part_list:after,
.rouletteMain_P9_N8 .rouletteMain_box_part_award,
.rouletteMain_P9_N8 .rouletteMain_box_part_list:after {
  animation-delay: 2.2s;
  animation-direction: alternate;
  font-weight: bold;
  font-family: 'ReadexPro-Bold', sans-serif;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  transform: rotate(-90deg);
  color: #dd3f07;
  font-size: 33px;
  text-shadow: -2px -2px 0 #ffffff, -2px -2px 0 #ffffff, -1px -2px 0 #ffffff, 0 -2px 0 #ffffff, 1px -2px 0 #ffffff, 1px -2px 0 #ffffff, 2px -2px 0 #ffffff, 2px -2px 0 #ffffff, 2px -2px 0 #ffffff,
    2px -1px 0 #ffffff, 2px -1px 0 #ffffff, 2px 0 0 #ffffff, 2px 1px 0 #ffffff, 2px 2px 0 #ffffff, 2px 1px 0 #ffffff, 2px 1px 0 #ffffff, 1px 1px 0 #ffffff, 2px 1px 0 #ffffff, 1px 1px 0 #ffffff,
    0 2px 0 #ffffff, -1px 1px 0 #ffffff, -2px 1px 0 #ffffff, -1px 1px 0 #ffffff, -2px 1px 0 #ffffff, -2px 1px 0 #ffffff, -2px 1px 0 #ffffff, -2px 1px 0 #ffffff, -2px 0 0 #ffffff, -2px -1px 0 #ffffff,
    -2px -2px 0 #ffffff, -2px -1px 0 #ffffff, -2px -2px 0 #ffffff;
}

.rouletteMain_P8_N8 > .rouletteMain_box_part li:nth-child(2n + 1) > .rouletteMain_box_part_award {
  color: #036153;
}

.rouletteMain_P6_N0 .rouletteMain_box_btn,
.rouletteMain_P6_N0 .rouletteMain_box_btn:after,
.rouletteMain_P6_N1 .rouletteMain_box_btn,
.rouletteMain_P6_N1 .rouletteMain_box_btn:after,
.rouletteMain_P6_N2 .rouletteMain_box_btn,
.rouletteMain_P6_N2 .rouletteMain_box_btn:after,
.rouletteMain_P6_N3 .rouletteMain_box_btn,
.rouletteMain_P6_N3 .rouletteMain_box_btn:after,
.rouletteMain_P6_N4 .rouletteMain_box_btn,
.rouletteMain_P6_N4 .rouletteMain_box_btn:after,
.rouletteMain_P6_N5 .rouletteMain_box_btn,
.rouletteMain_P6_N5 .rouletteMain_box_btn:after,
.rouletteMain_P6_N6 .rouletteMain_box_btn,
.rouletteMain_P6_N6 .rouletteMain_box_btn:after,
.rouletteMain_P6_N7 .rouletteMain_box_btn,
.rouletteMain_P6_N7 .rouletteMain_box_btn:after,
.rouletteMain_P6_N8 .rouletteMain_box_btn,
.rouletteMain_P6_N8 .rouletteMain_box_btn:after,
.rouletteMain_P7_N0 .rouletteMain_box_btn,
.rouletteMain_P7_N0 .rouletteMain_box_btn:after,
.rouletteMain_P7_N1 .rouletteMain_box_btn,
.rouletteMain_P7_N1 .rouletteMain_box_btn:after,
.rouletteMain_P7_N2 .rouletteMain_box_btn,
.rouletteMain_P7_N2 .rouletteMain_box_btn:after,
.rouletteMain_P7_N3 .rouletteMain_box_btn,
.rouletteMain_P7_N3 .rouletteMain_box_btn:after,
.rouletteMain_P7_N4 .rouletteMain_box_btn,
.rouletteMain_P7_N4 .rouletteMain_box_btn:after,
.rouletteMain_P7_N5 .rouletteMain_box_btn,
.rouletteMain_P7_N5 .rouletteMain_box_btn:after,
.rouletteMain_P7_N6 .rouletteMain_box_btn,
.rouletteMain_P7_N6 .rouletteMain_box_btn:after,
.rouletteMain_P7_N7 .rouletteMain_box_btn,
.rouletteMain_P7_N7 .rouletteMain_box_btn:after,
.rouletteMain_P7_N8 .rouletteMain_box_btn,
.rouletteMain_P7_N8 .rouletteMain_box_btn:after,
.rouletteMain_P8_N0 .rouletteMain_box_btn,
.rouletteMain_P8_N0 .rouletteMain_box_btn:after,
.rouletteMain_P8_N1 .rouletteMain_box_btn,
.rouletteMain_P8_N1 .rouletteMain_box_btn:after,
.rouletteMain_P8_N2 .rouletteMain_box_btn,
.rouletteMain_P8_N2 .rouletteMain_box_btn:after,
.rouletteMain_P8_N3 .rouletteMain_box_btn,
.rouletteMain_P8_N3 .rouletteMain_box_btn:after,
.rouletteMain_P8_N4 .rouletteMain_box_btn,
.rouletteMain_P8_N4 .rouletteMain_box_btn:after,
.rouletteMain_P8_N5 .rouletteMain_box_btn,
.rouletteMain_P8_N5 .rouletteMain_box_btn:after,
.rouletteMain_P8_N6 .rouletteMain_box_btn,
.rouletteMain_P8_N6 .rouletteMain_box_btn:after,
.rouletteMain_P8_N7 .rouletteMain_box_btn,
.rouletteMain_P8_N7 .rouletteMain_box_btn:after,
.rouletteMain_P8_N8 .rouletteMain_box_btn,
.rouletteMain_P8_N8 .rouletteMain_box_btn:after,
.rouletteMain_P9_N0 .rouletteMain_box_btn,
.rouletteMain_P9_N0 .rouletteMain_box_btn:after,
.rouletteMain_P9_N1 .rouletteMain_box_btn,
.rouletteMain_P9_N1 .rouletteMain_box_btn:after,
.rouletteMain_P9_N2 .rouletteMain_box_btn,
.rouletteMain_P9_N2 .rouletteMain_box_btn:after,
.rouletteMain_P9_N3 .rouletteMain_box_btn,
.rouletteMain_P9_N3 .rouletteMain_box_btn:after,
.rouletteMain_P9_N4 .rouletteMain_box_btn,
.rouletteMain_P9_N4 .rouletteMain_box_btn:after,
.rouletteMain_P9_N5 .rouletteMain_box_btn,
.rouletteMain_P9_N5 .rouletteMain_box_btn:after,
.rouletteMain_P9_N6 .rouletteMain_box_btn,
.rouletteMain_P9_N6 .rouletteMain_box_btn:after,
.rouletteMain_P9_N7 .rouletteMain_box_btn,
.rouletteMain_P9_N7 .rouletteMain_box_btn:after,
.rouletteMain_P9_N8 .rouletteMain_box_btn,
.rouletteMain_P9_N8 .rouletteMain_box_btn:after {
  animation-delay: 2.2s;
}

.rouletteMain_P6_N0 .rouletteMain_box_btn,
.rouletteMain_P6_N0 .rouletteMain_box_logo,
.rouletteMain_P6_N1 .rouletteMain_box_btn,
.rouletteMain_P6_N1 .rouletteMain_box_logo,
.rouletteMain_P6_N2 .rouletteMain_box_btn,
.rouletteMain_P6_N2 .rouletteMain_box_logo,
.rouletteMain_P6_N3 .rouletteMain_box_btn,
.rouletteMain_P6_N3 .rouletteMain_box_logo,
.rouletteMain_P6_N4 .rouletteMain_box_btn,
.rouletteMain_P6_N4 .rouletteMain_box_logo,
.rouletteMain_P6_N5 .rouletteMain_box_btn,
.rouletteMain_P6_N5 .rouletteMain_box_logo,
.rouletteMain_P6_N6 .rouletteMain_box_btn,
.rouletteMain_P6_N6 .rouletteMain_box_logo,
.rouletteMain_P6_N7 .rouletteMain_box_btn,
.rouletteMain_P6_N7 .rouletteMain_box_logo,
.rouletteMain_P6_N8 .rouletteMain_box_btn,
.rouletteMain_P6_N8 .rouletteMain_box_logo,
.rouletteMain_P7_N0 .rouletteMain_box_btn,
.rouletteMain_P7_N0 .rouletteMain_box_logo,
.rouletteMain_P7_N1 .rouletteMain_box_btn,
.rouletteMain_P7_N1 .rouletteMain_box_logo,
.rouletteMain_P7_N2 .rouletteMain_box_btn,
.rouletteMain_P7_N2 .rouletteMain_box_logo,
.rouletteMain_P7_N3 .rouletteMain_box_btn,
.rouletteMain_P7_N3 .rouletteMain_box_logo,
.rouletteMain_P7_N4 .rouletteMain_box_btn,
.rouletteMain_P7_N4 .rouletteMain_box_logo,
.rouletteMain_P7_N5 .rouletteMain_box_btn,
.rouletteMain_P7_N5 .rouletteMain_box_logo,
.rouletteMain_P7_N6 .rouletteMain_box_btn,
.rouletteMain_P7_N6 .rouletteMain_box_logo,
.rouletteMain_P7_N7 .rouletteMain_box_btn,
.rouletteMain_P7_N7 .rouletteMain_box_logo,
.rouletteMain_P7_N8 .rouletteMain_box_btn,
.rouletteMain_P7_N8 .rouletteMain_box_logo,
.rouletteMain_P8_N0 .rouletteMain_box_btn,
.rouletteMain_P8_N0 .rouletteMain_box_logo,
.rouletteMain_P8_N1 .rouletteMain_box_btn,
.rouletteMain_P8_N1 .rouletteMain_box_logo,
.rouletteMain_P8_N2 .rouletteMain_box_btn,
.rouletteMain_P8_N2 .rouletteMain_box_logo,
.rouletteMain_P8_N3 .rouletteMain_box_btn,
.rouletteMain_P8_N3 .rouletteMain_box_logo,
.rouletteMain_P8_N4 .rouletteMain_box_btn,
.rouletteMain_P8_N4 .rouletteMain_box_logo,
.rouletteMain_P8_N5 .rouletteMain_box_btn,
.rouletteMain_P8_N5 .rouletteMain_box_logo,
.rouletteMain_P8_N6 .rouletteMain_box_btn,
.rouletteMain_P8_N6 .rouletteMain_box_logo,
.rouletteMain_P8_N7 .rouletteMain_box_btn,
.rouletteMain_P8_N7 .rouletteMain_box_logo,
.rouletteMain_P8_N8 .rouletteMain_box_btn,
.rouletteMain_P8_N8 .rouletteMain_box_logo,
.rouletteMain_P9_N0 .rouletteMain_box_btn,
.rouletteMain_P9_N0 .rouletteMain_box_logo,
.rouletteMain_P9_N1 .rouletteMain_box_btn,
.rouletteMain_P9_N1 .rouletteMain_box_logo,
.rouletteMain_P9_N2 .rouletteMain_box_btn,
.rouletteMain_P9_N2 .rouletteMain_box_logo,
.rouletteMain_P9_N3 .rouletteMain_box_btn,
.rouletteMain_P9_N3 .rouletteMain_box_logo,
.rouletteMain_P9_N4 .rouletteMain_box_btn,
.rouletteMain_P9_N4 .rouletteMain_box_logo,
.rouletteMain_P9_N5 .rouletteMain_box_btn,
.rouletteMain_P9_N5 .rouletteMain_box_logo,
.rouletteMain_P9_N6 .rouletteMain_box_btn,
.rouletteMain_P9_N6 .rouletteMain_box_logo,
.rouletteMain_P9_N7 .rouletteMain_box_btn,
.rouletteMain_P9_N7 .rouletteMain_box_logo,
.rouletteMain_P9_N8 .rouletteMain_box_btn,
.rouletteMain_P9_N8 .rouletteMain_box_logo {
  transition-delay: 2.2s;
}

.rouletteMain_P6_N0 > .rouletteMain_box_part,
.rouletteMain_P7_N0 > .rouletteMain_box_part,
.rouletteMain_P8_N0 > .rouletteMain_box_part,
.rouletteMain_P9_N0 > .rouletteMain_box_part {
  animation-name: rouletteMain_P6789_N0;
}

.rouletteMain_P6_N0 .rouletteMain_box_part_list:first-of-type:after,
.rouletteMain_P7_N0 .rouletteMain_box_part_list:first-of-type:after,
.rouletteMain_P8_N0 .rouletteMain_box_part_list:first-of-type:after,
.rouletteMain_P9_N0 .rouletteMain_box_part_list:first-of-type:after {
  animation-name: roulettePrizeNumber0Shadow;
}

.rouletteMain_P6_N0 .rouletteMain_box_part_list:first-of-type > .rouletteMain_box_part_award,
.rouletteMain_P7_N0 .rouletteMain_box_part_list:first-of-type > .rouletteMain_box_part_award,
.rouletteMain_P8_N0 .rouletteMain_box_part_list:first-of-type > .rouletteMain_box_part_award,
.rouletteMain_P9_N0 .rouletteMain_box_part_list:first-of-type > .rouletteMain_box_part_award {
  animation-name: roulettePrizeNumberAward;
}

.rouletteMain_P6_N1 .rouletteMain_box_part_list:nth-of-type(2):after,
.rouletteMain_P7_N1 .rouletteMain_box_part_list:nth-of-type(2):after,
.rouletteMain_P8_N1 .rouletteMain_box_part_list:nth-of-type(2):after,
.rouletteMain_P9_N1 .rouletteMain_box_part_list:nth-of-type(2):after {
  animation-name: roulettePrizeNumberText;
}

.rouletteMain_P6_N1 .rouletteMain_box_part_list:nth-of-type(2) > .rouletteMain_box_part_award,
.rouletteMain_P7_N1 .rouletteMain_box_part_list:nth-of-type(2) > .rouletteMain_box_part_award,
.rouletteMain_P8_N1 .rouletteMain_box_part_list:nth-of-type(2) > .rouletteMain_box_part_award,
.rouletteMain_P9_N1 .rouletteMain_box_part_list:nth-of-type(2) > .rouletteMain_box_part_award {
  animation-name: roulettePrizeNumberAward;
}

.rouletteMain_P6_N2 .rouletteMain_box_part_list:nth-of-type(3):after,
.rouletteMain_P7_N2 .rouletteMain_box_part_list:nth-of-type(3):after,
.rouletteMain_P8_N2 .rouletteMain_box_part_list:nth-of-type(3):after,
.rouletteMain_P9_N2 .rouletteMain_box_part_list:nth-of-type(3):after {
  animation-name: roulettePrizeNumberText;
}

.rouletteMain_P6_N2 .rouletteMain_box_part_list:nth-of-type(3) > .rouletteMain_box_part_award,
.rouletteMain_P7_N2 .rouletteMain_box_part_list:nth-of-type(3) > .rouletteMain_box_part_award,
.rouletteMain_P8_N2 .rouletteMain_box_part_list:nth-of-type(3) > .rouletteMain_box_part_award,
.rouletteMain_P9_N2 .rouletteMain_box_part_list:nth-of-type(3) > .rouletteMain_box_part_award {
  animation-name: roulettePrizeNumberAward;
}

.rouletteMain_P6_N3 .rouletteMain_box_part_list:nth-of-type(4):after,
.rouletteMain_P7_N3 .rouletteMain_box_part_list:nth-of-type(4):after,
.rouletteMain_P8_N3 .rouletteMain_box_part_list:nth-of-type(4):after,
.rouletteMain_P9_N3 .rouletteMain_box_part_list:nth-of-type(4):after {
  animation-name: roulettePrizeNumberText;
}

.rouletteMain_P6_N3 .rouletteMain_box_part_list:nth-of-type(4) > .rouletteMain_box_part_award,
.rouletteMain_P7_N3 .rouletteMain_box_part_list:nth-of-type(4) > .rouletteMain_box_part_award,
.rouletteMain_P8_N3 .rouletteMain_box_part_list:nth-of-type(4) > .rouletteMain_box_part_award,
.rouletteMain_P9_N3 .rouletteMain_box_part_list:nth-of-type(4) > .rouletteMain_box_part_award {
  animation-name: roulettePrizeNumberAward;
}

.rouletteMain_P6_N4 .rouletteMain_box_part_list:nth-of-type(5):after,
.rouletteMain_P7_N4 .rouletteMain_box_part_list:nth-of-type(5):after,
.rouletteMain_P8_N4 .rouletteMain_box_part_list:nth-of-type(5):after,
.rouletteMain_P9_N4 .rouletteMain_box_part_list:nth-of-type(5):after {
  animation-name: roulettePrizeNumberText;
}

.rouletteMain_P6_N4 .rouletteMain_box_part_list:nth-of-type(5) > .rouletteMain_box_part_award,
.rouletteMain_P7_N4 .rouletteMain_box_part_list:nth-of-type(5) > .rouletteMain_box_part_award,
.rouletteMain_P8_N4 .rouletteMain_box_part_list:nth-of-type(5) > .rouletteMain_box_part_award,
.rouletteMain_P9_N4 .rouletteMain_box_part_list:nth-of-type(5) > .rouletteMain_box_part_award {
  animation-name: roulettePrizeNumberAward;
}

.rouletteMain_P6_N5 .rouletteMain_box_part_list:nth-of-type(6):after,
.rouletteMain_P7_N5 .rouletteMain_box_part_list:nth-of-type(6):after,
.rouletteMain_P8_N5 .rouletteMain_box_part_list:nth-of-type(6):after,
.rouletteMain_P9_N5 .rouletteMain_box_part_list:nth-of-type(6):after {
  animation-name: roulettePrizeNumberText;
}

.rouletteMain_P6_N5 .rouletteMain_box_part_list:nth-of-type(6) > .rouletteMain_box_part_award,
.rouletteMain_P7_N5 .rouletteMain_box_part_list:nth-of-type(6) > .rouletteMain_box_part_award,
.rouletteMain_P8_N5 .rouletteMain_box_part_list:nth-of-type(6) > .rouletteMain_box_part_award,
.rouletteMain_P9_N5 .rouletteMain_box_part_list:nth-of-type(6) > .rouletteMain_box_part_award {
  animation-name: roulettePrizeNumberAward;
}

.rouletteMain_P7_N6 .rouletteMain_box_part_list:nth-of-type(7):after,
.rouletteMain_P8_N6 .rouletteMain_box_part_list:nth-of-type(7):after,
.rouletteMain_P9_N6 .rouletteMain_box_part_list:nth-of-type(7):after {
  animation-name: roulettePrizeNumberText;
}

.rouletteMain_P7_N6 .rouletteMain_box_part_list:nth-of-type(7) > .rouletteMain_box_part_award,
.rouletteMain_P8_N6 .rouletteMain_box_part_list:nth-of-type(7) > .rouletteMain_box_part_award,
.rouletteMain_P9_N6 .rouletteMain_box_part_list:nth-of-type(7) > .rouletteMain_box_part_award {
  animation-name: roulettePrizeNumberAward;
}

.rouletteMain_P8_N7 .rouletteMain_box_part_list:nth-of-type(8):after,
.rouletteMain_P9_N7 .rouletteMain_box_part_list:nth-of-type(8):after {
  animation-name: roulettePrizeNumberText;
}

.rouletteMain_P8_N7 .rouletteMain_box_part_list:nth-of-type(8) > .rouletteMain_box_part_award,
.rouletteMain_P9_N7 .rouletteMain_box_part_list:nth-of-type(8) > .rouletteMain_box_part_award {
  animation-name: roulettePrizeNumberAward;
}

.rouletteMain_P9_N1 > .rouletteMain_box_part {
  animation-name: rouletteMain_P9_N1;
}

.rouletteMain_P9_N2 > .rouletteMain_box_part {
  animation-name: rouletteMain_P9_N2;
}

.rouletteMain_P9_N3 > .rouletteMain_box_part {
  animation-name: rouletteMain_P6_N2_P9_N3;
}

.rouletteMain_P9_N4 > .rouletteMain_box_part {
  animation-name: rouletteMain_P9_N4;
}

.rouletteMain_P9_N5 > .rouletteMain_box_part {
  animation-name: rouletteMain_P9_N5;
}

.rouletteMain_P9_N6 > .rouletteMain_box_part {
  animation-name: rouletteMain_P6_N4_P9_N6;
}

.rouletteMain_P9_N7 > .rouletteMain_box_part {
  animation-name: rouletteMain_P9_N7;
}

.rouletteMain_P9_N8 > .rouletteMain_box_part {
  animation-name: rouletteMain_P9_N8;
}

.rouletteMain_P9_N8 .rouletteMain_box_part_list:nth-of-type(9):after {
  animation-name: roulettePrizeNumberText;
}

.rouletteMain_P9_N8 .rouletteMain_box_part_list:nth-of-type(9) > .rouletteMain_box_part_award {
  animation-name: roulettePrizeNumberAward;
}

.rouletteMain_P8_N1 > .rouletteMain_box_part {
  animation-name: rouletteMain_P8_N1;
}

.rouletteMain_P8_N2 > .rouletteMain_box_part {
  animation-name: rouletteMain_P8_N2;
}

.rouletteMain_P8_N3 > .rouletteMain_box_part {
  animation-name: rouletteMain_P8_N3;
}

.rouletteMain_P8_N4 > .rouletteMain_box_part {
  animation-name: rouletteMain_P6_N3_P8_N4;
}

.rouletteMain_P8_N5 > .rouletteMain_box_part {
  animation-name: rouletteMain_P8_N5;
}

.rouletteMain_P8_N6 > .rouletteMain_box_part {
  animation-name: rouletteMain_P8_N6;
}

.rouletteMain_P8_N7 > .rouletteMain_box_part {
  animation-name: rouletteMain_P8_N7;
}

.rouletteMain_P7_N1 > .rouletteMain_box_part {
  animation-name: rouletteMain_P7_N1;
}

.rouletteMain_P7_N2 > .rouletteMain_box_part {
  animation-name: rouletteMain_P7_N2;
}

.rouletteMain_P7_N3 > .rouletteMain_box_part {
  animation-name: rouletteMain_P7_N3;
}

.rouletteMain_P7_N4 > .rouletteMain_box_part {
  animation-name: rouletteMain_P7_N4;
}

.rouletteMain_P7_N5 > .rouletteMain_box_part {
  animation-name: rouletteMain_P7_N5;
}

.rouletteMain_P7_N6 > .rouletteMain_box_part {
  animation-name: rouletteMain_P7_N6;
}

.rouletteMain_P6_N1 > .rouletteMain_box_part {
  animation-name: rouletteMain_P6_N1;
}

.rouletteMain_P6_N2 > .rouletteMain_box_part {
  animation-name: rouletteMain_P6_N2_P9_N3;
}

.rouletteMain_P6_N3 > .rouletteMain_box_part {
  animation-name: rouletteMain_P6_N3_P8_N4;
}

.rouletteMain_P6_N4 > .rouletteMain_box_part {
  animation-name: rouletteMain_P6_N4_P9_N6;
}

.rouletteMain_P6_N5 > .rouletteMain_box_part {
  animation-name: rouletteMain_P6_N5;
}

.roulette.roulette-popupOpen .rouletteMain:after,
.roulette.roulette-popupOpen .rouletteMain:before,
.roulette.roulette-popupOpen .rouletteMain_awards_item.special > div,
.roulette.roulette-popupOpen .rouletteMain_box_btn,
.roulette.roulette-popupOpen .rouletteMain_box_btn:after,
.roulette.roulette-popupOpen .rouletteMain_box_coinWrap,
.roulette.roulette-popupOpen .rouletteMain_box_part_award,
.roulette.roulette-popupOpen .rouletteMain_box_part_list:after,
.roulette.roulette-popupOpen .rouletteMain_box_part_list:before,
.roulette.roulette-popupOpen .rouletteMain_box_wrap:after {
  animation-play-state: paused;
}

.roulette.roulette-popupOpen > .roulettePopup {
  z-index: 3;
}

.roulette.roulette-popupOpen .roulettePopup_wrap:before {
  backdrop-filter: blur(7px);
  background-color: rgba(0, 0, 0, 0.7);
}

.roulette.roulette-popupOpen .roulettePopup_box {
  opacity: 1;
  transform: scaleX(1);
}

.roulette.roulette-popupOpen .roulettePopup_box:after,
.roulette.roulette-popupOpen .roulettePopup_box:before {
  animation-duration: 25s;
  animation-iteration-count: infinite;
  animation-name: rouletteDecoShake;
}

@media screen and (max-width: 1366px) {
  .rouletteMain_winner_item {
    font-size: 14px;
    line-height: 34px;
  }
}

@media screen and (max-width: 1280px) {
  .rouletteMain_winner_item {
    font-size: 13px;
    line-height: 32px;
  }
}

@media screen and (max-width: 1024px) {
  .rouletteMain_box_part_list {
    font-size: 16px;
  }

  .rouletteMain_winner_item {
    font-size: 12px;
    line-height: 30px;
  }
}

@media screen and (min-width: 1440px) {
  .rouletteMain_P6_N1 .rouletteMain_box_part_list:nth-of-type(2):before,
  .rouletteMain_P6_N1 .rouletteMain_box_part_list:nth-of-type(3):before,
  .rouletteMain_P6_N2 .rouletteMain_box_part_list:nth-of-type(3):before,
  .rouletteMain_P6_N2 .rouletteMain_box_part_list:nth-of-type(4):before,
  .rouletteMain_P6_N3 .rouletteMain_box_part_list:nth-of-type(4):before,
  .rouletteMain_P6_N3 .rouletteMain_box_part_list:nth-of-type(5):before,
  .rouletteMain_P6_N4 .rouletteMain_box_part_list:nth-of-type(5):before,
  .rouletteMain_P6_N4 .rouletteMain_box_part_list:nth-of-type(6):before,
  .rouletteMain_P6_N5 .rouletteMain_box_part_list:first-of-type:before,
  .rouletteMain_P6_N5 .rouletteMain_box_part_list:nth-of-type(6):before,
  .rouletteMain_P7_N1 .rouletteMain_box_part_list:nth-of-type(2):before,
  .rouletteMain_P7_N1 .rouletteMain_box_part_list:nth-of-type(3):before,
  .rouletteMain_P7_N2 .rouletteMain_box_part_list:nth-of-type(3):before,
  .rouletteMain_P7_N2 .rouletteMain_box_part_list:nth-of-type(4):before,
  .rouletteMain_P7_N3 .rouletteMain_box_part_list:nth-of-type(4):before,
  .rouletteMain_P7_N3 .rouletteMain_box_part_list:nth-of-type(5):before,
  .rouletteMain_P7_N4 .rouletteMain_box_part_list:nth-of-type(5):before,
  .rouletteMain_P7_N4 .rouletteMain_box_part_list:nth-of-type(6):before,
  .rouletteMain_P7_N5 .rouletteMain_box_part_list:nth-of-type(6):before,
  .rouletteMain_P7_N5 .rouletteMain_box_part_list:nth-of-type(7):before,
  .rouletteMain_P7_N6 .rouletteMain_box_part_list:first-of-type:before,
  .rouletteMain_P7_N6 .rouletteMain_box_part_list:nth-of-type(7):before,
  .rouletteMain_P8_N1 .rouletteMain_box_part_list:nth-of-type(2):before,
  .rouletteMain_P8_N1 .rouletteMain_box_part_list:nth-of-type(3):before,
  .rouletteMain_P8_N2 .rouletteMain_box_part_list:nth-of-type(3):before,
  .rouletteMain_P8_N2 .rouletteMain_box_part_list:nth-of-type(4):before,
  .rouletteMain_P8_N3 .rouletteMain_box_part_list:nth-of-type(4):before,
  .rouletteMain_P8_N3 .rouletteMain_box_part_list:nth-of-type(5):before,
  .rouletteMain_P8_N4 .rouletteMain_box_part_list:nth-of-type(5):before,
  .rouletteMain_P8_N4 .rouletteMain_box_part_list:nth-of-type(6):before,
  .rouletteMain_P8_N5 .rouletteMain_box_part_list:nth-of-type(6):before,
  .rouletteMain_P8_N5 .rouletteMain_box_part_list:nth-of-type(7):before,
  .rouletteMain_P8_N6 .rouletteMain_box_part_list:nth-of-type(7):before,
  .rouletteMain_P8_N6 .rouletteMain_box_part_list:nth-of-type(8):before,
  .rouletteMain_P8_N7 .rouletteMain_box_part_list:first-of-type:before,
  .rouletteMain_P8_N7 .rouletteMain_box_part_list:nth-of-type(8):before,
  .rouletteMain_P9_N1 .rouletteMain_box_part_list:nth-of-type(2):before,
  .rouletteMain_P9_N1 .rouletteMain_box_part_list:nth-of-type(3):before,
  .rouletteMain_P9_N2 .rouletteMain_box_part_list:nth-of-type(3):before,
  .rouletteMain_P9_N2 .rouletteMain_box_part_list:nth-of-type(4):before,
  .rouletteMain_P9_N3 .rouletteMain_box_part_list:nth-of-type(4):before,
  .rouletteMain_P9_N3 .rouletteMain_box_part_list:nth-of-type(5):before,
  .rouletteMain_P9_N4 .rouletteMain_box_part_list:nth-of-type(5):before,
  .rouletteMain_P9_N4 .rouletteMain_box_part_list:nth-of-type(6):before,
  .rouletteMain_P9_N5 .rouletteMain_box_part_list:nth-of-type(6):before,
  .rouletteMain_P9_N5 .rouletteMain_box_part_list:nth-of-type(7):before,
  .rouletteMain_P9_N6 .rouletteMain_box_part_list:nth-of-type(7):before,
  .rouletteMain_P9_N6 .rouletteMain_box_part_list:nth-of-type(8):before,
  .rouletteMain_P9_N7 .rouletteMain_box_part_list:nth-of-type(8):before,
  .rouletteMain_P9_N7 .rouletteMain_box_part_list:nth-of-type(9):before,
  .rouletteMain_P9_N8 .rouletteMain_box_part_list:first-of-type:before,
  .rouletteMain_P9_N8 .rouletteMain_box_part_list:nth-of-type(9):before {
    animation-name: roulettePrizeNumber-pigSize;
  }

  .rouletteMain_P6_N0 .rouletteMain_box_part_list:first-of-type > .rouletteMain_box_part_award,
  .rouletteMain_P6_N1 .rouletteMain_box_part_list:nth-of-type(2) > .rouletteMain_box_part_award,
  .rouletteMain_P6_N2 .rouletteMain_box_part_list:nth-of-type(3) > .rouletteMain_box_part_award,
  .rouletteMain_P6_N3 .rouletteMain_box_part_list:nth-of-type(4) > .rouletteMain_box_part_award,
  .rouletteMain_P6_N4 .rouletteMain_box_part_list:nth-of-type(5) > .rouletteMain_box_part_award,
  .rouletteMain_P6_N5 .rouletteMain_box_part_list:nth-of-type(6) > .rouletteMain_box_part_award,
  .rouletteMain_P7_N0 .rouletteMain_box_part_list:first-of-type > .rouletteMain_box_part_award,
  .rouletteMain_P7_N1 .rouletteMain_box_part_list:nth-of-type(2) > .rouletteMain_box_part_award,
  .rouletteMain_P7_N2 .rouletteMain_box_part_list:nth-of-type(3) > .rouletteMain_box_part_award,
  .rouletteMain_P7_N3 .rouletteMain_box_part_list:nth-of-type(4) > .rouletteMain_box_part_award,
  .rouletteMain_P7_N4 .rouletteMain_box_part_list:nth-of-type(5) > .rouletteMain_box_part_award,
  .rouletteMain_P7_N5 .rouletteMain_box_part_list:nth-of-type(6) > .rouletteMain_box_part_award,
  .rouletteMain_P7_N6 .rouletteMain_box_part_list:nth-of-type(7) > .rouletteMain_box_part_award,
  .rouletteMain_P8_N0 .rouletteMain_box_part_list:first-of-type > .rouletteMain_box_part_award,
  .rouletteMain_P8_N1 .rouletteMain_box_part_list:nth-of-type(2) > .rouletteMain_box_part_award,
  .rouletteMain_P8_N2 .rouletteMain_box_part_list:nth-of-type(3) > .rouletteMain_box_part_award,
  .rouletteMain_P8_N3 .rouletteMain_box_part_list:nth-of-type(4) > .rouletteMain_box_part_award,
  .rouletteMain_P8_N4 .rouletteMain_box_part_list:nth-of-type(5) > .rouletteMain_box_part_award,
  .rouletteMain_P8_N5 .rouletteMain_box_part_list:nth-of-type(6) > .rouletteMain_box_part_award,
  .rouletteMain_P8_N6 .rouletteMain_box_part_list:nth-of-type(7) > .rouletteMain_box_part_award,
  .rouletteMain_P8_N7 .rouletteMain_box_part_list:nth-of-type(8) > .rouletteMain_box_part_award,
  .rouletteMain_P9_N0 .rouletteMain_box_part_list:first-of-type > .rouletteMain_box_part_award,
  .rouletteMain_P9_N1 .rouletteMain_box_part_list:nth-of-type(2) > .rouletteMain_box_part_award,
  .rouletteMain_P9_N2 .rouletteMain_box_part_list:nth-of-type(3) > .rouletteMain_box_part_award,
  .rouletteMain_P9_N3 .rouletteMain_box_part_list:nth-of-type(4) > .rouletteMain_box_part_award,
  .rouletteMain_P9_N4 .rouletteMain_box_part_list:nth-of-type(5) > .rouletteMain_box_part_award,
  .rouletteMain_P9_N5 .rouletteMain_box_part_list:nth-of-type(6) > .rouletteMain_box_part_award,
  .rouletteMain_P9_N6 .rouletteMain_box_part_list:nth-of-type(7) > .rouletteMain_box_part_award,
  .rouletteMain_P9_N7 .rouletteMain_box_part_list:nth-of-type(8) > .rouletteMain_box_part_award,
  .rouletteMain_P9_N8 .rouletteMain_box_part_list:nth-of-type(9) > .rouletteMain_box_part_award {
    animation-name: roulettePrizeNumberAward-pigSize;
  }

  .rouletteMain_P6_N1 .rouletteMain_box_part_list:nth-of-type(2):after,
  .rouletteMain_P6_N2 .rouletteMain_box_part_list:nth-of-type(3):after,
  .rouletteMain_P6_N3 .rouletteMain_box_part_list:nth-of-type(4):after,
  .rouletteMain_P6_N4 .rouletteMain_box_part_list:nth-of-type(5):after,
  .rouletteMain_P6_N5 .rouletteMain_box_part_list:nth-of-type(6):after,
  .rouletteMain_P7_N1 .rouletteMain_box_part_list:nth-of-type(2):after,
  .rouletteMain_P7_N2 .rouletteMain_box_part_list:nth-of-type(3):after,
  .rouletteMain_P7_N3 .rouletteMain_box_part_list:nth-of-type(4):after,
  .rouletteMain_P7_N4 .rouletteMain_box_part_list:nth-of-type(5):after,
  .rouletteMain_P7_N5 .rouletteMain_box_part_list:nth-of-type(6):after,
  .rouletteMain_P7_N6 .rouletteMain_box_part_list:nth-of-type(7):after,
  .rouletteMain_P8_N1 .rouletteMain_box_part_list:nth-of-type(2):after,
  .rouletteMain_P8_N2 .rouletteMain_box_part_list:nth-of-type(3):after,
  .rouletteMain_P8_N3 .rouletteMain_box_part_list:nth-of-type(4):after,
  .rouletteMain_P8_N4 .rouletteMain_box_part_list:nth-of-type(5):after,
  .rouletteMain_P8_N5 .rouletteMain_box_part_list:nth-of-type(6):after,
  .rouletteMain_P8_N6 .rouletteMain_box_part_list:nth-of-type(7):after,
  .rouletteMain_P8_N7 .rouletteMain_box_part_list:nth-of-type(8):after,
  .rouletteMain_P9_N1 .rouletteMain_box_part_list:nth-of-type(2):after,
  .rouletteMain_P9_N2 .rouletteMain_box_part_list:nth-of-type(3):after,
  .rouletteMain_P9_N3 .rouletteMain_box_part_list:nth-of-type(4):after,
  .rouletteMain_P9_N4 .rouletteMain_box_part_list:nth-of-type(5):after,
  .rouletteMain_P9_N5 .rouletteMain_box_part_list:nth-of-type(6):after,
  .rouletteMain_P9_N6 .rouletteMain_box_part_list:nth-of-type(7):after,
  .rouletteMain_P9_N7 .rouletteMain_box_part_list:nth-of-type(8):after,
  .rouletteMain_P9_N8 .rouletteMain_box_part_list:nth-of-type(9):after {
    animation-name: roulettePrizeNumberText-pigSize;
  }

  .rouletteMain {
    padding-bottom: 830px;
  }

  .rouletteMain_head {
    margin-bottom: 70px;
  }

  .rouletteMain_awards_medals_1,
  .rouletteMain_awards_medals_2,
  .rouletteMain_awards_medals_3,
  .rouletteMain_awards_medals_4 {
    font-size: 20px;
  }

  .rouletteMain_box_part_list:after {
    font-size: 66px;
    text-shadow: -3px -4px 0 #dd3f07, -2px -4px 0 #dd3f07, -1px -4px 0 #dd3f07, 0 -4px 0 #dd3f07, 1px -4px 0 #dd3f07, 2px -4px 0 #dd3f07, 3px -4px 0 #dd3f07, 4px -4px 0 #dd3f07, 4px -3px 0 #dd3f07,
      4px -2px 0 #dd3f07, 4px -1px 0 #dd3f07, 4px 0 0 #dd3f07, 4px 1px 0 #dd3f07, 4px 2px 0 #dd3f07, 4px 3px 0 #dd3f07, 4px 4px 0 #dd3f07, 3px 4px 0 #dd3f07, 2px 4px 0 #dd3f07, 1px 4px 0 #dd3f07,
      0 4px 0 #dd3f07, -1px 4px 0 #dd3f07, -2px 4px 0 #dd3f07, -3px 4px 0 #dd3f07, -4px 4px 0 #dd3f07, -4px 3px 0 #dd3f07, -4px 2px 0 #dd3f07, -4px 1px 0 #dd3f07, -4px 0 0 #dd3f07, -4px -1px 0 #dd3f07,
      -4px -2px 0 #dd3f07, -4px -3px 0 #dd3f07, -4px -4px 0 #dd3f07;
  }

  .rouletteMain_box_part_list:first-of-type:after {
    height: 67px;
    text-align: center;
    width: 70px;
  }

  .rouletteMain_box_part_award {
    /*padding-bottom: 18px*/
  }

  .rouletteMain_winner_head_item {
    font-size: 15px;
    line-height: 34px;
  }

  .rouletteMain_P6_N0 .rouletteMain_box_part_list:first-of-type:after,
  .rouletteMain_P7_N0 .rouletteMain_box_part_list:first-of-type:after,
  .rouletteMain_P8_N0 .rouletteMain_box_part_list:first-of-type:after,
  .rouletteMain_P9_N0 .rouletteMain_box_part_list:first-of-type:after {
    animation-name: roulettePrizeNumber0Shadow-pigSize;
  }

  .roulettePopup_box > p {
    font-size: 21px;
    padding: 16px 0;
  }

  .roulettePopup_box > h3 {
    font-size: 38px;
    padding-bottom: 16px;
    text-shadow: 0 0 18px #6404f3, 0 0 36px #6404f3, 0 1px 36px #6404f3;
  }

  .roulettePopup_box > img {
    width: 230px;
  }

  .roulettePopup_tenTimes {
    height: 188px;
    padding-bottom: 10px;
  }

  .roulettePopup_tenTimes > h3 {
    font-size: 20px;
    padding-bottom: 10px;
    text-shadow: 0 0 10px #6404f3, 0 0 22px #6404f3, 0 1px 22px #6404f3;
  }

  .roulettePopup_tenTimes > h3:before {
    font-size: 16px;
    height: 20px;
    line-height: 20px;
    margin-right: 6px;
    width: 20px;
  }
}

.roulette .roulette-period {
  color: #ffefbd;
  font-size: 21px;
  margin-top: -30px;
  position: absolute;
  text-shadow: 0 1px 5px rgba(0, 0, 0, 0.8);
}

.roulette .unavailable {
  align-content: center;
  align-items: center;
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  text-shadow: 0 2px 5px #000;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.roulette .unavailable h2 {
  color: #ffefbd;
  font-size: 29px;
  margin-top: 10px;
  text-align: center;
  width: 100%;
}

.roulette .unavailable i {
  font-size: 80px;
}

.roulette .unavailable + div {
  filter: grayscale(0.7) blur(2px) brightness(0.3);
}
</style>
